import React, { useEffect } from "react";
import "./aboutUs.style.scss";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import OurService from "../../components/ourService/ourService.component";
import ContactUsForm from "../../components/contactUsForm/contactUsForm.component";
import image1 from "../../assets/image/aboutus1.jpg";
import image2 from "../../assets/image/aboutus2.jpg";
import image3 from "../../assets/image/aboutus3.png";
import punseva from "../../assets/image/our work/punseva.png";
import aanantmarketing from "../../assets/image/our work/aanantmarketing.png";
import gpgs from "../../assets/image/our work/gpgs.png";
import thesince from "../../assets/image/our work/thesince.png";

const AboutUs = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section
        className="breadcrumb__area breadcrumb-height include-bg p-relative "
        style={{
          background:
            "url(https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/breadcurmb.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h3 className="breadcrumb__title">About Us</h3>
                <div className="breadcrumb__list p-0 mt-15">
                  {/* Breadcrumb NavXT 7.1.0 */}
                  <span property="itemListElement" typeof="ListItem">
                    <Link
                      property="item"
                      typeof="WebPage"
                      title="Go to Sixart ."
                      className="home"
                      to="/"
                    >
                      <span property="name">Home </span>
                    </Link>
                    <meta property="position" content={1} />
                  </span>
                  <span className="dvdr">
                    <i className="fa fa-angle-right" />
                  </span>
                  <span property="itemListElement" typeof="ListItem">
                    <span
                      property="name"
                      className="post post-page current-item"
                    >
                      About Us
                    </span>
                    <meta
                      property="url"
                      content="https://data.themeim.com/wp/sixart/about-us/"
                    />
                    <meta property="position" content={2} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        data-elementor-type="wp-page"
        data-elementor-id={46}
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-ddf66e4 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="ddf66e4"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ee1e3e0"
              data-id="ee1e3e0"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-819d835 elementor-widget elementor-widget-about"
                  data-id="819d835"
                  data-element_type="widget"
                  data-widget_type="about.default"
                >
                  <div className="elementor-widget-container">
                    <div className="tp-about-us-area pt-120 pb-120">
                      <div className="container">
                        <div className="row">
                          <div
                            className="col-xl-5 col-lg-6 wow tpfadeLeft"
                            data-wow-duration=".7s"
                            data-wow-delay=".5s"
                          >
                            <div className="ab-inner-content">
                              <h2 className="ab-title-xs mb-25">
                                SNM Solution provides design, coding,
                                <br />
                                development, and branding services.
                              </h2>
                              <p>
                                At our foundation, we are developers that use
                                cutting-edge technology to provide innovative
                                solutions that are specific to your
                                requirements. We are committed to providing
                                seamless experiences and endeavor to establish
                                industry standards by providing forward-thinking
                                solutions that adapt to the continually evolving
                                digital world.
                              </p>
                              <div className="tp-inner-list">
                                <ul>
                                  <li>
                                    <i className="far fa-check" /> FRONTEND 90%
                                  </li>
                                  <li>
                                    <i className="far fa-check" /> Web
                                    Development 95%
                                  </li>
                                  <li>
                                    <i className="far fa-check" /> Ui\Ux design
                                    90%
                                  </li>
                                  <li>
                                    <i className="far fa-check" /> Branding
                                    Design 80%
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-xl-7 col-lg-6 wow tpfadeRight"
                            data-wow-duration=".7s"
                            data-wow-delay=".8s"
                          >
                            <div className="tp-img-inner text-end">
                              <div
                                className="row gx-3"
                                style={{
                                  justifyContent: "center",
                                  margin: "auto",
                                }}
                              >
                                <div className="col-xl-7 col-lg-6 col-md-7 col-7">
                                  <div className="ab-inner-img">
                                    <img
                                      decoding="async"
                                      src={image3}
                                      alt=""
                                      style={{ background: "white" }}
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-xl-5 col-lg-6 col-md-5 col-5">
                                  <div className="ab-inner-img mb-20">
                                    <img
                                      decoding="async"
                                      src={image2}

                                      alt=""
                                    />
                                  </div>
                                  <div className="ab-inner-img">
                                    <img
                                      decoding="async"
                                      src={image1}

                                      alt=""
                                    />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section
          className="elementor-section elementor-top-section elementor-element elementor-element-240ab00 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="240ab00"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82209a9"
              data-id="82209a9"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-c92b32c elementor-widget elementor-widget-tp-fact"
                  data-id="c92b32c"
                  data-element_type="widget"
                  data-widget_type="tp-fact.default"
                >
                  <div className="elementor-widget-container">
                    <div className="tp-counter-area pb-90">
                      <div className="container">
                        <div className="row">
                          <div
                            className="col-xl-3 col-lg-3 col-md-6 col-12 mb-30 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="0.5s"
                          >
                            <div className="counter-item text-center">
                              <h4>
                                <span className="counter">850</span>+
                              </h4>
                              <h3>PROJECTS DELIVERD</h3>
                            </div>
                          </div>
                          <div
                            className="col-xl-3 col-lg-3 col-md-6 col-12 mb-30 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="0.7s"
                          >
                            <div className="counter-item text-center">
                              <h4>
                                <span className="counter">24</span>+
                              </h4>
                              <h3>YEARS OF OPERATION</h3>
                            </div>
                          </div>
                          <div
                            className="col-xl-3 col-lg-3 col-md-6 col-12 mb-30 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="0.9s"
                          >
                            <div className="counter-item text-center">
                              <h4>
                                <span className="counter">84</span>+
                              </h4>
                              <h3>SPECIALIST MEMBER</h3>
                            </div>
                          </div>
                          <div
                            className="col-xl-3 col-lg-3 col-md-6 col-12 mb-30 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="1s"
                          >
                            <div className="counter-item text-center">
                              <h4>
                                <span className="counter">280</span>+
                              </h4>
                              <h3>PROJECTS DELIVERD</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <section className="counter__area d-none">
                      <div className="container">
                        <div className="counter__inner grey-bg-2ss">
                          <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <div className="counter__item d-flex align-items-start tp-el-content counter__item-border">
                                <div className="counter__icon mr-15"></div>
                                <div className="counter__content">
                                  <h3 className="tp-el-title">
                                    PROJECTS DELIVERD
                                  </h3>
                                  <h4 className="tp-el-subtitle">
                                    <span className="counter">850</span>+
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <div className="counter__item d-flex align-items-start tp-el-content counter__item-border">
                                <div className="counter__icon mr-15"></div>
                                <div className="counter__content">
                                  <h3 className="tp-el-title">
                                    YEARS OF OPERATION
                                  </h3>
                                  <h4 className="tp-el-subtitle">
                                    <span className="counter">24</span>+
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <div className="counter__item d-flex align-items-start tp-el-content counter__item-border">
                                <div className="counter__icon mr-15"></div>
                                <div className="counter__content">
                                  <h3 className="tp-el-title">
                                    SPECIALIST MEMBER
                                  </h3>
                                  <h4 className="tp-el-subtitle">
                                    <span className="counter">84</span>+
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <div className="counter__item d-flex align-items-start tp-el-content ">
                                <div className="counter__icon mr-15"></div>
                                <div className="counter__content">
                                  <h3 className="tp-el-title">
                                    PROJECTS DELIVERD
                                  </h3>
                                  <h4 className="tp-el-subtitle">
                                    <span className="counter">280</span>+
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <OurService />
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-4274e24 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="4274e24"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e926ddb"
              data-id="e926ddb"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-5d587fe elementor-widget elementor-widget-project-slider"
                  data-id="5d587fe"
                  data-element_type="widget"
                  data-widget_type="project-slider.default"
                >
                  <div className="elementor-widget-container">
                    <div className="tp-case-area case-grident-bg pt-120 pb-120 fix">
                      <div className="container-fluid">
                        <div className="row g-0 justify-content-center">
                          <div className="col-xl-6">
                            <div
                              className="tp-case-title-box mb-60 "
                              style={{ textAlign: "center" }}
                            >
                              <h3 className="tp-section-subtitle">Our Works</h3>
                              <h2 className="tp-section-title-sm">
                                Look at Our Recent <br /> Works
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="tp-case-wrapper">
                          <div className="tp-case-active">
                            <Slider {...settings}>
                              <div className="tp-case-slide">
                                <div className="tp-case-item text-center p-relative">
                                  <div className="tp-case-item__thumb fix">
                                    <a href="https://aanantmarketing.com/">
                                      <img
                                        decoding="async"
                                        src={aanantmarketing}
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                  <div className="tp-circle-text">
                                    <a href="https://aanantmarketing.com/">
                                      Our <br /> Work
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="tp-case-slide">
                                <div className="tp-case-item text-center p-relative">
                                  <div className="tp-case-item__thumb fix">
                                    <a href="https://gsapg.netlify.app/">
                                      <img decoding="async" src={gpgs} alt="" />
                                    </a>
                                  </div>
                                  <div className="tp-circle-text">
                                    <a href="https://gsapg.netlify.app/">
                                      Our <br /> Work
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="tp-case-slide">
                                <div className="tp-case-item text-center p-relative">
                                  <div className="tp-case-item__thumb fix">
                                    <a href="https://thesciein.com/">
                                      <img
                                        decoding="async"
                                        src={thesince}
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                  <div className="tp-circle-text">
                                    <a href="https://thesciein.com/">
                                      Our <br /> Work
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="tp-case-slide">
                                <div className="tp-case-item text-center p-relative">
                                  <div className="tp-case-item__thumb fix">
                                    <a href="https://punseva-demo.onrender.com/">
                                      <img
                                        decoding="async"
                                        src={punseva}
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                  <div className="tp-circle-text">
                                    <a href="https://punseva-demo.onrender.com/">
                                      Our <br /> Work
                                    </a>
                                  </div>
                                </div>
                              </div>
                              
                            </Slider>
                          </div>
                        </div>
                        <div className="row">
                          <div className="case-button mt-30 text-center">
                            <Link
                              to="/projects"
                              target="_self"
                              rel="nofollow"
                              className="tp-btn"
                            >
                              SEE MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section
          className="elementor-section elementor-top-section elementor-element elementor-element-7f4b896 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="7f4b896"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-46b5231"
              data-id="46b5231"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-695a96a elementor-widget elementor-widget-tp-team"
                  data-id="695a96a"
                  data-element_type="widget"
                  data-widget_type="tp-team.default"
                >
                  <div className="elementor-widget-container">
                    <div className="tp-taem-area light-version pb-120">
                      <div className="container">
                        <div className="row">
                          <div className="tp-team-section-area mb-60" style={{textAlign:"center"}}>
                            <h4 className="tp-section-subtitle ">Our Team</h4>
                            <h2 className="tp-section-title-sm ">
                              Meet with our team
                            </h2>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-xl-3 col-lg-3 col-md-6 col-12 mb-60 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="0.5s"
                          >
                            <div className="tp-team text-center team-inner">
                              <div className="tp-team-thumb p-relative">
                                <div className="fix">
                                  <img
                                    decoding="async"
                                    src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/team-4.jpg"
                                    alt=""
                                  />
                                </div>
                                <div className="tp-team-thumb-icon">
                                  <a href="https://data.themeim.com/wp/sixart/about-me/">
                                    <i className="far fa-long-arrow-right" />
                                  </a>
                                </div>
                              </div>
                              <div className="tp-team-content">
                                <h3 className="tp-team-title ">
                                  <a href="https://data.themeim.com/wp/sixart/about-me/">
                                    Alextina Ditarson
                                  </a>
                                </h3>{" "}
                                <span>UX Designer</span>
                              </div>
                              <div className="tp-team-social">
                                <a href="#">
                                  <i className="fab fa-facebook-f" />
                                </a>
                                <a href="#">
                                  <i className="fab fa-twitter" />
                                </a>
                                <a href="#">
                                  <i className="fab fa-youtube" />
                                </a>
                                <a href="#">
                                  <i className="fab fa-pinterest-p" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-xl-3 col-lg-3 col-md-6 col-12 mb-60 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="0.7s"
                          >
                            <div className="tp-team text-center team-inner">
                              <div className="tp-team-thumb p-relative">
                                <div className="fix">
                                  <img
                                    decoding="async"
                                    src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/team-1.jpg"
                                    alt=""
                                  />
                                </div>
                                <div className="tp-team-thumb-icon">
                                  <a href="https://data.themeim.com/wp/sixart/about-me/">
                                    <i className="far fa-long-arrow-right" />
                                  </a>
                                </div>
                              </div>
                              <div className="tp-team-content">
                                <h3 className="tp-team-title  tp-team-title ">
                                  <a href="https://data.themeim.com/wp/sixart/about-me/">
                                    Alextina Ditarson
                                  </a>
                                </h3>{" "}
                                <span>WordPress Developer</span>
                              </div>
                              <div className="tp-team-social">
                                <a href="#">
                                  <i className="fab fa-facebook-f" />
                                </a>
                                <a href="#">
                                  <i className="fab fa-twitter" />
                                </a>
                                <a href="#">
                                  <i className="fab fa-youtube" />
                                </a>
                                <a href="#">
                                  <i className="fab fa-pinterest-p" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-xl-3 col-lg-3 col-md-6 col-12 mb-60 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="0.9s"
                          >
                            <div className="tp-team text-center team-inner">
                              <div className="tp-team-thumb p-relative">
                                <div className="fix">
                                  <img
                                    decoding="async"
                                    src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/team-2.jpg"
                                    alt=""
                                  />
                                </div>
                                <div className="tp-team-thumb-icon">
                                  <a href="https://data.themeim.com/wp/sixart/about-me/">
                                    <i className="far fa-long-arrow-right" />
                                  </a>
                                </div>
                              </div>
                              <div className="tp-team-content">
                                <h3 className="tp-team-title  tp-team-title  tp-team-title ">
                                  <a href="https://data.themeim.com/wp/sixart/about-me/">
                                    Jonathan Mercado
                                  </a>
                                </h3>{" "}
                                <span>Logo Designer</span>
                              </div>
                              <div className="tp-team-social">
                                <a href="#">
                                  <i className="fab fa-facebook-f" />
                                </a>
                                <a href="#">
                                  <i className="fab fa-twitter" />
                                </a>
                                <a href="#">
                                  <i className="fab fa-youtube" />
                                </a>
                                <a href="#">
                                  <i className="fab fa-pinterest-p" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-xl-3 col-lg-3 col-md-6 col-12 mb-60 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="1s"
                          >
                            <div className="tp-team text-center team-inner">
                              <div className="tp-team-thumb p-relative">
                                <div className="fix">
                                  <img
                                    decoding="async"
                                    src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/team-3.jpg"
                                    alt=""
                                  />
                                </div>
                                <div className="tp-team-thumb-icon">
                                  <a href="https://data.themeim.com/wp/sixart/about-me/">
                                    <i className="far fa-long-arrow-right" />
                                  </a>
                                </div>
                              </div>
                              <div className="tp-team-content">
                                <h3 className="tp-team-title  tp-team-title  tp-team-title  tp-team-title ">
                                  <a href="https://data.themeim.com/wp/sixart/about-me/">
                                    David D. Rethman
                                  </a>
                                </h3>{" "}
                                <span>Plugin Developer</span>
                              </div>
                              <div className="tp-team-social">
                                <a href="#">
                                  <i className="fab fa-facebook-f" />
                                </a>
                                <a href="#">
                                  <i className="fab fa-twitter" />
                                </a>
                                <a href="#">
                                  <i className="fab fa-youtube" />
                                </a>
                                <a href="#">
                                  <i className="fab fa-pinterest-p" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section
          className="elementor-section elementor-top-section elementor-element elementor-element-358115c elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="358115c"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e5a1362"
              data-id="e5a1362"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-42df032 elementor-widget elementor-widget-testimonial"
                  data-id="42df032"
                  data-element_type="widget"
                  data-widget_type="testimonial.default"
                >
                  <div className="elementor-widget-container">
                    
                    <div className="tp-testimonial-area grey-bg pt-120 pb-120 fix">
                      <div className="container-fluid">
                        <div className="row g-0 justify-content-center">
                          <div className="col-xl-4">
                            <div
                              className="tp-testtimonial-title-box mb-60 "
                              style={{ textAlign: "center" }}
                            >
                              <h3 className="tp-section-subtitle">
                                Testimonial
                              </h3>
                              <h2 className="tp-section-title-sm">
                                Trusted by Clients.
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-slide-wrapper">
                          <div className="testimonial-active-three">
                            <Slider {...settings}>
                              <div
                                className="tp-testimonial-three text-center wow tpfadeUp elementor-repeater-item-6c95013"
                                data-wow-duration="0.9s"
                                data-wow-delay="0.3s"
                              >
                                <div className="tp-testimonial-three__content">
                                  <div className="tp-testimonial-three__content-icon">
                                    <i className="fas fa-quote-right" />
                                  </div>
                                  <div className="tp-testimonial-three__content-text">
                                    <p>
                                      "Agency is the most valuable business
                                      resource we have EVER purchased. This is
                                      simply unbelievable!"
                                    </p>
                                  </div>
                                </div>
                                <div className="tp-testimonial-three__user">
                                  <div className="tp-testimonial-three__user-img">
                                    <img
                                      decoding="async"
                                      src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/testi-img-6.png"
                                      alt=""
                                    />
                                  </div>
                                  <div className="tp-testimonial-three__user-content">
                                    <h4 className="user-title">Guy Hawkins</h4>
                                    <span>Founder of Edardx</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tp-testimonial-three text-center wow tpfadeUp elementor-repeater-item-26f6ebe"
                                data-wow-duration="0.9s"
                                data-wow-delay="0.5s"
                              >
                                <div className="tp-testimonial-three__content">
                                  <div className="tp-testimonial-three__content-icon">
                                    <i className="fas fa-quote-right" />
                                  </div>
                                  <div className="tp-testimonial-three__content-text">
                                    <p>
                                      A formal statement that testifies to a
                                      persons character or the qualifications,
                                      or the quality of a product or service.
                                    </p>
                                  </div>
                                </div>
                                <div className="tp-testimonial-three__user">
                                  <div className="tp-testimonial-three__user-img">
                                    <img
                                      decoding="async"
                                      src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/testi-img-7.png"
                                      alt=""
                                    />
                                  </div>
                                  <div className="tp-testimonial-three__user-content">
                                    <h4 className="user-title">
                                      Savannah Nguyen
                                    </h4>
                                    <span>Founder of Ryws</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tp-testimonial-three text-center wow tpfadeUp elementor-repeater-item-f4bc0ec"
                                data-wow-duration="0.9s"
                                data-wow-delay="0.7s"
                              >
                                <div className="tp-testimonial-three__content">
                                  <div className="tp-testimonial-three__content-icon">
                                    <i className="fas fa-quote-right" />
                                  </div>
                                  <div className="tp-testimonial-three__content-text">
                                    <p>
                                      Our office is something we are pleased
                                      with. We consider it the little magnet; it
                                      is wanting to come here and afterward
                                      difficult to leave it. Our office is
                                      additionally a big name
                                    </p>
                                  </div>
                                </div>
                                <div className="tp-testimonial-three__user">
                                  <div className="tp-testimonial-three__user-img">
                                    <img
                                      decoding="async"
                                      src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/testi-img-8.png"
                                      alt=""
                                    />
                                  </div>
                                  <div className="tp-testimonial-three__user-content">
                                    <h4 className="user-title">Robert Fox</h4>
                                    <span>Founder of Etyuh</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tp-testimonial-three text-center wow tpfadeUp elementor-repeater-item-c746bf6"
                                data-wow-duration="0.9s"
                                data-wow-delay="0.9s"
                              >
                                <div className="tp-testimonial-three__content">
                                  <div className="tp-testimonial-three__content-icon">
                                    <i className="fas fa-quote-right" />
                                  </div>
                                  <div className="tp-testimonial-three__content-text">
                                    <p>
                                      When potential customers are researching
                                      you online, they're getting to know you
                                      through the content on your website. So
                                      understandably, many
                                    </p>
                                  </div>
                                </div>
                                <div className="tp-testimonial-three__user">
                                  <div className="tp-testimonial-three__user-img">
                                    <img
                                      decoding="async"
                                      src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/testi-img-9.png"
                                      alt=""
                                    />
                                  </div>
                                  <div className="tp-testimonial-three__user-content">
                                    <h4 className="user-title">
                                      Bessie Cooper
                                    </h4>
                                    <span>Founder of Etyuh</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tp-testimonial-three text-center wow tpfadeUp elementor-repeater-item-c5b1d11"
                                data-wow-duration="0.9s"
                                data-wow-delay="1s"
                              >
                                <div className="tp-testimonial-three__content">
                                  <div className="tp-testimonial-three__content-icon">
                                    <i className="fas fa-quote-right" />
                                  </div>
                                  <div className="tp-testimonial-three__content-text">
                                    <p>
                                      Honest endorsement of your product or
                                      service that usually comes from a
                                      customer, colleague,{" "}
                                    </p>
                                  </div>
                                </div>
                                <div className="tp-testimonial-three__user">
                                  <div className="tp-testimonial-three__user-img">
                                    <img
                                      decoding="async"
                                      src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/testi-img-10.png"
                                      alt=""
                                    />
                                  </div>
                                  <div className="tp-testimonial-three__user-content">
                                    <h4 className="user-title">
                                      Dianne Russell
                                    </h4>
                                    <span>Founder of Nrfus</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tp-testimonial-three text-center wow tpfadeUp elementor-repeater-item-69eedc4"
                                data-wow-duration="0.9s"
                                data-wow-delay="1.1s"
                              >
                                <div className="tp-testimonial-three__content">
                                  <div className="tp-testimonial-three__content-icon">
                                    <i className="fas fa-quote-right" />
                                  </div>
                                  <div className="tp-testimonial-three__content-text">
                                    <p>
                                      Our office is something we are pleased
                                      with. We consider it the little magnet; it
                                      is wanting to come here and afterward
                                      difficult to leave it. Our office is
                                      additionally a big name
                                    </p>
                                  </div>
                                </div>
                                <div className="tp-testimonial-three__user">
                                  <div className="tp-testimonial-three__user-img">
                                    <img
                                      decoding="async"
                                      src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/testi-img-8.png"
                                      alt=""
                                    />
                                  </div>
                                  <div className="tp-testimonial-three__user-content">
                                    <h4 className="user-title">Robert Fox</h4>
                                    <span>Founder of Etyuh</span>
                                  </div>
                                </div>
                              </div>
                            </Slider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <ContactUsForm />
      </div>
    </>
  );
};

export default AboutUs;
