import React, { useEffect } from "react";
import "./serviceDetail.style.scss";
import { Link, useParams } from "react-router-dom";
import ContactUsForm from "../../components/contactUsForm/contactUsForm.component";
const serviceDetails = {
  "web-development": {
    title: "Boost Online Visibility with Our Complete Web Development Services",
    titleContant: `Discover the cutting edge of innovative web creation with our complete portfolio of services designed to satisfy your online requirements. We use cutting-edge technologies to realize your vision, from creating compelling user interfaces to developing solid backend solutions.
    `,

    subTitle:
      "Introducing Our Entire Service Offering for Dynamic Digital Solutions",
    subTitleContant: `We are able to deliver excellent results for every project since our staff of seasoned developers is skilled in a wide range of frameworks and technologies. We possess the knowledge and skills to realize your ideas, whether they include developing scalable backend systems, generating engaging user experiences, or utilizing 3D visuals.


    We provide everything you need, from frontend frameworks that facilitate smooth user interactions to backend solutions that guarantee dependable performance and security. Our services cover everything from developing and designing responsive websites to integrating APIs and deploying custom applications.`,
  },
  "mobile-development": {
    title:
      "Use Next-Generation App Development Solutions to Transform Mobile Experiences.",
    titleContant: `With the full range of app development services offered by our company, take a mobile innovation trip. Our expertise lies in utilizing modern technology to craft user-friendly and fascinating mobile experiences that engage customers and boost business growth.`,
    subTitle:
      "Introducing Our Customized Service Suite for Adaptable Mobile Solutions",
    subTitleContant: ` We can deliver outstanding results for every project since our team of skilled developers has a thorough understanding of a variety of mobile frameworks and platforms. Our proficiency can realize your idea, be it designing user-friendly interfaces, integrating sophisticated functionalities, or putting strong backend systems into place.

    Our services range from cross-platform solutions that optimize efficiency and reach to native app development for iOS and Android that caters to your unique requirements. We guarantee that we can manage every part of your project with accuracy and skill because of our broad range of talents, which include UI/UX design, frontend and backend programming, API integrations, and custom app deployment.
    `,
  },
  "custom-solution": {
    title:
      "From Idea to Reality: Creating Personalized Solutions Based on Your Dreams",
    titleContant: `With our carefully designed custom solution services, you may take an innovative approach from conception to execution and realize what you desire. Our company specializes at offering customized solutions that address your particular needs and goals, giving you the tools you need to successfully realize your vision.`,
    subTitle: "Bringing Your Ideas to Life with Our Personalized Success Plan",
    subTitleContant: `We provide a full range of services to help you at every step of the process, from conception and planning to execution and deployment. Our skills cover UI/UX design, API integrations, frontend and backend development, and more, so we can handle every part of your project with painstaking attention to detail.

    We are here to support your goals, whether you are an established company looking to innovate and stand out from the crowd or a visionary entrepreneur with a game-changing idea. Our persistent dedication to quality, innovation, and customer happiness enables us to offer solutions that not only meet but also surpass your expectations, magnificently realizing your ambitions.`,
  },
  "web-design": {
    title:
      "Crafting Extraordinary Digital Experiences: Introducing Our Web Design Solutions",
    titleContant: `Begin a journey of digital innovation with our custom web design solutions, precisely developed to improve your online presence and engage your audience in meaningful ways. Our studio specializes in providing unique web design services that combine creativity, functionality, and user-centricity to create engaging online experiences.
    `,
    subTitle:
      "Customized Solutions for Enhancing Your Online Presence and Engaging Your Audience",
    subTitleContant: ` We provide a broad range of web design services to match your individual requirements, including smart user interfaces, seamless navigation, responsive layouts, and appealing imagery. Our design process is collaborative and iterative, so we can capture your feedback at every stage and provide a final product that surpasses your expectations. Contact us today to discover more about how our web design services may help you improve your online presence and create unique online experiences that connect with your target audience. Let's work together to bring your concept to life and achieve success in the aggressive internet business.
    `,
  },
  "seo": {
    title:
      "Increase Your Online Presence: Unlocking the Power of SEO Solutions.",
    titleContant: `Begin a transforming journey to increase your online exposure with our entire range of SEO services, strategically designed to launch your brand to new heights. Our business specializes in providing bespoke solutions that increase organic traffic, improve search engine rankings, and enhance your digital footprint with precision and skill.
    `,
    subTitle:
      "Introducing Our Customized Package of Services for Increasing Your Digital Visibility",
    subTitleContant: `Whether you're an independent company looking to boost its online presence or a global organization looking to dominate your sector, we're here to assist you confidently navigate the complexities of SEO. With an uncompromising commitment to excellence, innovation, and customer happiness, we're dedicated to exceeding your expectations and propelling your business to digital success.

    Contact us today to find out how our SEO solutions may help you reach your business goals and maximize the potential of your online presence. Let's work together to increase your brand's visibility, attract targeted traffic, and achieve long-term success in the competitive digital space.
    `,
  },
};

const ServiceDetail = () => {
  const { servieType } = useParams();
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <main>
      {/* breadcrumb area start */}
      <section
        className="breadcrumb__area breadcrumb-height include-bg p-relative"
        data-background="assets/img/breadcrumb/breadcurmb.jpg"
        style={{
          backgroundImage:
            'url("https://data.themeim.com/html/sixart/assets/img/breadcrumb/breadcurmb.jpg")',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h3 className="breadcrumb__title">Service Details</h3>
                <div
                  className="breadcrumb__list wow tpfadeUp"
                  data-wow-duration=".9s"
                  style={{
                    visibility: "visible",
                    animationDuration: "0.9s",
                    animationName: "tpfadeUp",
                  }}
                >
                  <span>
                    <Link to="/">Home</Link>
                  </span>
                  <span className="dvdr">
                    <i className="fa fa-angle-right" />
                  </span>
                  <span>Service Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* breadcrumb area end */}
      {/* sv-details area start */}
      <div className="sv-details-area pt-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="sv-details-content">
                <div className="sv-details-thumb mb-50">
                  <img
                    src="https://data.themeim.com/html/sixart/assets/img/service/sv-details-1.jpg"
                    alt=""
                  />
                </div>
                <h4 className="sv-details-title mb-60">
                  {serviceDetails[servieType]?.title}
                </h4>
                <p className="pb-30">
                  {serviceDetails[servieType]?.titleContant}
                </p>
              </div>
              <div className="sv-details-content sv-details-md-space">
                <h5 className="sv-details-title-sm pb-20">
                  {serviceDetails[servieType]?.subTitle}
                </h5>
                <p>{serviceDetails[servieType]?.subTitleContant}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* sv-details area end */}
      {/* tp-cta-area-start */}
      <div className="tp-cta-area cta-space-three pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <div className="cta-item p-relative">
                <h3 className="cta-big-text">
                  <a href="#">Think.</a>
                </h3>
                <div className="cta-effect-img">
                  <img
                    src="https://data.themeim.com/html/sixart/assets/img/cta/cta-2.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <div className="cta-item p-relative">
                <h3 className="cta-big-text">
                  <a href="#">Make.</a>
                </h3>
                <div className="cta-effect-img">
                  <img
                    src="https://data.themeim.com/html/sixart/assets/img/cta/cta-3.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <div className="cta-item p-relative">
                <h3 className="cta-big-text">
                  <a href="#">Solve.</a>
                </h3>
                <div className="cta-effect-img">
                  <img
                    src="https://data.themeim.com/html/sixart/assets/img/cta/cta-4.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* tp-cta-area-end */}
      {/* tp-contact-area-start */}
      
      <ContactUsForm />
      {/* tp-contact-area-end */}
    </main>
  );
};

export default ServiceDetail;
