import { useEffect } from "react";
import "./projects.style.scss";
import ContactUsForm from "../../components/contactUsForm/contactUsForm.component";
import { Link } from "react-router-dom";
import punseva from "../../assets/image/our work/punseva.png";
import aanantmarketing from "../../assets/image/our work/aanantmarketing.png";
import gpgs from "../../assets/image/our work/gpgs.png";
import thesince from "../../assets/image/our work/thesince.png";

const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section
        className="breadcrumb__area breadcrumb-height include-bg p-relative "
        style={{
          background:
            "url(https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/breadcurmb.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h3 className="breadcrumb__title">Our Works</h3>
                <div className="breadcrumb__list p-0 mt-15">
                  {/* Breadcrumb NavXT 7.1.0 */}
                  <span property="itemListElement" typeof="ListItem">
                    <Link to="/" className="home">
                      <span property="name">Home </span>

                    </Link>
                   
                    <meta property="position" content={1} />
                  </span>
                  <span className="dvdr">
                    <i className="fa fa-angle-right" />
                  </span>
                  <span property="itemListElement" typeof="ListItem">
                    <span
                      property="name"
                      className="post post-page current-item"
                    >
                      our works
                    </span>
                    <meta
                      property="url"
                      content="https://data.themeim.com/wp/sixart/case-studies/"
                    />
                    <meta property="position" content={2} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        data-elementor-type="wp-page"
        data-elementor-id={48}
        className="elementor elementor-48"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e1a057d elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="e1a057d"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-706ddb2"
              data-id="706ddb2"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-2e62cb9 elementor-widget elementor-widget-project-gallery"
                  data-id="2e62cb9"
                  data-element_type="widget"
                  data-widget_type="project-gallery.default"
                >
                  <div className="elementor-widget-container">
                    <div className="tp-case-area pt-120 pb-120">
                      <div className="container">
                        <div className="row gx-20">
                          <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                            <div className="tp-case-thumb p-relative fix mb-20">
                              <img
                                decoding="async"
                                src={aanantmarketing}
                                alt=""
                              />
                              <div className="case-icon">
                                <a href="https://aanantmarketing.com/">
                                  <i className="fas fa-arrow-alt-up" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                            <div className="tp-case-thumb p-relative fix mb-20">
                              <img
                                decoding="async"
                                src={gpgs}
                                alt=""
                              />
                              <div className="case-icon">
                                <a href="https://gsapg.netlify.app/">
                                  <i className="fas fa-arrow-alt-up" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                            <div className="tp-case-thumb p-relative fix mb-20">
                              <img
                                decoding="async"
                                src={thesince}
                                alt=""
                              />
                              <div className="case-icon">
                                <a href="https://thesciein.com/">
                                  <i className="fas fa-arrow-alt-up" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                            <div className="tp-case-thumb p-relative fix mb-20">
                              <img
                                decoding="async"
                                src={punseva}
                                alt=""
                              />
                              <div className="case-icon">
                                <a href="https://punseva-demo.onrender.com/">
                                  <i className="fas fa-arrow-alt-up" />
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                            <div className="tp-case-thumb p-relative fix mb-20">
                              <img
                                decoding="async"
                                src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/11/case-5-768x521.jpg"
                                alt=""
                              />
                              <div className="case-icon">
                                <a href="https://data.themeim.com/wp/sixart/case-details/">
                                  <i className="fas fa-arrow-alt-up" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                            <div className="tp-case-thumb p-relative fix mb-20">
                              <img
                                decoding="async"
                                src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/11/case-6-768x521.jpg"
                                alt=""
                              />
                              <div className="case-icon">
                                <a href="https://data.themeim.com/wp/sixart/case-details/">
                                  <i className="fas fa-arrow-alt-up" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                            <div className="tp-case-thumb p-relative fix mb-20">
                              <img
                                decoding="async"
                                src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/11/case-7-768x521.jpg"
                                alt=""
                              />
                              <div className="case-icon">
                                <a href="https://data.themeim.com/wp/sixart/case-details/">
                                  <i className="fas fa-arrow-alt-up" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                            <div className="tp-case-thumb p-relative fix mb-20">
                              <img
                                decoding="async"
                                src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/11/case-8-768x521.jpg"
                                alt=""
                              />
                              <div className="case-icon">
                                <a href="https://data.themeim.com/wp/sixart/case-details/">
                                  <i className="fas fa-arrow-alt-up" />
                                </a>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        {/* <div className="row">
                          <div className="col-xl-12">
                            <div className="tp-case-button text-center mt-40">
                              <a
                                href="https://data.themeim.com/wp/sixart/case-studies/"
                                target="_self"
                                rel="nofollow"
                                className="tp-btn"
                              >
                                ALL CASE STUDEIS
                              </a>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactUsForm />
      </div>
    </>
  );
};

export default Projects;
