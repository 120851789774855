// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCR1ptgbiag64H8iJdlSY9KAkpcvcVr2p0",
  authDomain: "imposing-union-386503.firebaseapp.com",
  projectId: "imposing-union-386503",
  storageBucket: "imposing-union-386503.appspot.com",
  messagingSenderId: "282078416149",
  appId: "1:282078416149:web:a6c19565c21ed60dae6d1c",
  measurementId: "G-QRDCB1L9T1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);