import "./home.style.scss";
import { useEffect, useState } from "react";
import grident_bg from "../../assets/img/slider/slider-grident-bg.png";
import case_grident_bg from "../../assets/img/slider/case-grident-shape.png";
import testi_border_shape from "../../assets/img/testimonial/testi-border-shape.png";
import blog_grident_bg from "../../assets/img/blog/blog-gridient-shape.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import OurService from "../../components/ourService/ourService.component";
// import aanantmarketing from "../../assets/image/Business logo/aanantmarketing.png";
// import gprobo from "../../assets/image/Business logo/gprobo.jpeg";
// import thesince from "../../assets/image/Business logo/thesince.png";
import punseva from "../../assets/image/our work/punseva.png";
import aanantmarketing from "../../assets/image/our work/aanantmarketing.png";
import gpgs from "../../assets/image/our work/gpgs.png";
import thesince from "../../assets/image/our work/thesince.png";
import ContactUsForm from "../../components/contactUsForm/contactUsForm.component";


const Home = () => {
  const [activeSearchOtipn, setActiveSearchOption] = useState("rent");
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="home">
      <>
        {/* tp-offcanvus-area-start */}

        <div className="body-overlay" />
        {/* tp-offcanvus-area-end */}
        <main>
          {/* tp-slider-area-start */}
          <div
            className="tp-hero-area hero-grideant-bg"
            data-background="assets/img/slider/slider-grident-bg.png"
            style={{ background: `url(${grident_bg})` }}
          >
            <div className="container">
              <div className="row align-items-center hero-space">
                <div className="col-xl-6 col-lg-6">
                  <div className="tp-hero">
                    <div className="tp-hero__section-box">
                      <h4
                        className="tp-section-subtitle  wow tpfadeUp"
                        data-wow-duration=".7s"
                        data-wow-delay=".3s"
                      >
                        Best solution we offer you
                      </h4>
                      <h2
                        className="tp-slider-title wow tpfadeUp"
                        data-wow-duration=".7s"
                        data-wow-delay=".6s"
                      >
                        Make a good plan &amp; grow your business
                      </h2>
                      <p
                        className="wow tpfadeUp"
                        data-wow-duration=".7s"
                        data-wow-delay=".8s"
                      >
                        We assist people in turning their ideas into reality.
                      </p>
                    </div>
                    <div
                      className="tp-hero__wrapper d-flex align-items-center wow tpfadeUp"
                      data-wow-duration=".7s"
                      data-wow-delay="1s"
                    >
                      <div className="tp-hero__button">
                        <Link className="tp-btn" to="projects">
                          Our Works
                        </Link>
                      </div>
                      {/* <div className="tp-hero__play-button">
                        <a
                          className="popup-video"
                          href="https://www.youtube.com/watch?v=hiEb1m7CXH4"
                        >
                          <i className="fas fa-play" />
                        </a>
                        <span>See Inside</span>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="tp-hero__wrapper-thumb p-relative">
                    <div className="tp-hero-shape-one">
                      <img
                       src="assets/img/slider/hero-star-shape.png "
                        alt=""
                      />
                    </div>
                    <div className="tp-hero-shape-two">
                      <img
                        src="assets/img/slider/hero-circle-shape.png "
                        alt=""
                      />
                    </div>
                    <div className="tp-hero-shape-three">
                      <img
                        src="assets/img/slider/hero-angel-shape.png "
                        alt=""
                      />
                    </div>
                    <div className="tp-hero-shape-four">
                      <img
                        src="assets/img/slider/hero-star-sm-shape.png"
                        alt=""
                      />
                    </div>
                    <div
                      className="tp-hero__thumb text-start text-lg-end wow tpfadeRight"
                      data-wow-duration=".9s"
                      data-wow-delay="1.2s"
                      style={{ scale: "0.9" }}
                    >
                      <img src="assets/img/slider/hero-1.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-xl-5 col-lg-5 wow tpfadeUp"
                  data-wow-duration=".7s"
                  data-wow-delay=".3s"
                >
                  {/* <div className="tp-slider-exprience">
                    <div className="tp-slider-exprience__content">
                      <h4 className="tp-slider-exp-title">
                        24 Year of Experience
                      </h4>
                      <p>
                        “Lorem Ipsum is simply dummy text of printing and
                        typesetting industry Ipsum is dummy text of the printing
                        industry”
                      </p>
                    </div>
                    <div className="tp-slider-exprience__client-info d-flex align-items-center">
                      <div className="tp-slider-exprience__img">
                        <img src="assets/img/slider/hero-sm-img.png" alt="" />
                      </div>
                      <div className="tp-slider-exprience__meta">
                        <h5 className="tp-client-name">Darrell Steward</h5>
                        <span>
                          Founder of (Sixart <i className="fas fa-star" /> - 5
                          star Agency)
                        </span>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col-xl-7 col-lg-7">
                  <div className="row">
                    <div
                      className="col-xl-6 col-lg-6 col-md-6 wow tpfadeUp"
                      data-wow-duration=".7s"
                      data-wow-delay=".5s"
                    >
                      <div className="tp-slider-exp-item mb-30">
                        <div className="tp-slider-exp-item__icon icon-bg-green">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_289_84)">
                              <path
                                d="M23.2969 0H20.4375C20.0489 0 19.7344 0.314484 19.7344 0.703125V1.40625H13.9799C13.6886 0.589594 12.9155 0 12 0C11.0845 0 10.3114 0.589594 10.0201 1.40625H4.26562V0.703125C4.26562 0.314484 3.95114 0 3.5625 0H0.703125C0.314484 0 0 0.314484 0 0.703125V3.51562C0 3.90427 0.314484 4.21875 0.703125 4.21875H3.5625C3.95114 4.21875 4.26562 3.90427 4.26562 3.51562V2.80312H7.15331C4.71314 4.32755 3.10416 7.005 2.91183 10.0038C2.06817 10.28 1.45312 11.0653 1.45312 12C1.45312 13.1632 2.39934 14.1094 3.5625 14.1094C4.72566 14.1094 5.67188 13.1632 5.67188 12C5.67188 11.1028 5.10652 10.34 4.31531 10.0356C4.55522 6.69225 6.88809 3.87431 10.1331 3.07031C10.4841 3.74892 11.1847 4.21875 12 4.21875C12.8153 4.21875 13.5159 3.74892 13.8669 3.07031C17.1119 3.87427 19.4448 6.6922 19.6847 10.0356C18.8935 10.34 18.3281 11.1028 18.3281 12C18.3281 13.1632 19.2743 14.1094 20.4375 14.1094C21.6007 14.1094 22.5469 13.1632 22.5469 12C22.5469 11.0653 21.9318 10.28 21.0882 10.0038C20.9958 7.005 19.2869 4.33692 16.8467 2.8125H19.7344V3.51562C19.7344 3.90427 20.0489 4.21875 20.4375 4.21875H23.2969C23.6855 4.21875 24 3.90427 24 3.51562V0.703125C24 0.314484 23.6855 0 23.2969 0Z"
                                fill="currentColor"
                              />
                              <path
                                d="M16.7835 23.1279C16.4157 22.0025 15.3561 21.1406 14.1093 21.1406H9.89052C8.64365 21.1406 7.58408 22.0025 7.2163 23.1279C7.07451 23.5618 7.44365 24 7.90021 24H16.0996C16.5561 24 16.9253 23.5618 16.7835 23.1279Z"
                                fill="currentColor"
                              />
                              <path
                                d="M18.21 14.4225L12.7031 6.16217V12.8327C13.5198 13.1239 14.1094 13.8971 14.1094 14.8125C14.1094 15.9757 13.1632 16.9219 12 16.9219C10.9369 16.9219 9.89063 15.9757 9.89063 14.8125C9.89063 13.8971 10.4802 13.1239 11.2969 12.8327V6.16217L5.79001 14.4225C5.60391 14.7013 5.64099 15.0728 5.8779 15.3097C7.16869 16.6005 7.99379 18.2634 8.31221 20.0471C8.80065 19.849 9.33188 19.7344 9.89063 19.7344H14.1094C14.6681 19.7344 15.1994 19.8489 15.6878 20.047C16.0062 18.2632 16.8313 16.6005 18.1221 15.3097C18.359 15.0728 18.3961 14.7013 18.21 14.4225Z"
                                fill="currentColor"
                              />
                              <path
                                d="M12 14.1094C11.6121 14.1094 11.2969 14.4246 11.2969 14.8125C11.2969 15.2004 11.6121 15.5156 12 15.5156C12.3879 15.5156 12.7031 15.2004 12.7031 14.8125C12.7031 14.4246 12.3879 14.1094 12 14.1094Z"
                                fill="currentColor"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_289_84">
                                <rect
                                  width={24}
                                  height={24}
                                  fill="currentColor"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="tp-slider-exp-item__content">
                          <h4 className="tp-exp-sm-title">
                            Service we can <br /> help you with
                          </h4>
                        </div>
                        <div className="tp-slider-exp-item__service-link">
                          <div className="tp-link tp-link-green">
                            <Link to="/service">
                              <svg
                                width={34}
                                height={16}
                                viewBox="0 0 34 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                  fill="#202026"
                                />
                              </svg>
                              <span>Our Service</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-6 col-lg-6 col-md-6 wow tpfadeUp"
                      data-wow-duration=".7s"
                      data-wow-delay=".7s"
                    >
                      <div className="tp-slider-exp-item mb-30">
                        <div className="tp-slider-exp-item__icon">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_289_84)">
                              <path
                                d="M23.2969 0H20.4375C20.0489 0 19.7344 0.314484 19.7344 0.703125V1.40625H13.9799C13.6886 0.589594 12.9155 0 12 0C11.0845 0 10.3114 0.589594 10.0201 1.40625H4.26562V0.703125C4.26562 0.314484 3.95114 0 3.5625 0H0.703125C0.314484 0 0 0.314484 0 0.703125V3.51562C0 3.90427 0.314484 4.21875 0.703125 4.21875H3.5625C3.95114 4.21875 4.26562 3.90427 4.26562 3.51562V2.80312H7.15331C4.71314 4.32755 3.10416 7.005 2.91183 10.0038C2.06817 10.28 1.45312 11.0653 1.45312 12C1.45312 13.1632 2.39934 14.1094 3.5625 14.1094C4.72566 14.1094 5.67188 13.1632 5.67188 12C5.67188 11.1028 5.10652 10.34 4.31531 10.0356C4.55522 6.69225 6.88809 3.87431 10.1331 3.07031C10.4841 3.74892 11.1847 4.21875 12 4.21875C12.8153 4.21875 13.5159 3.74892 13.8669 3.07031C17.1119 3.87427 19.4448 6.6922 19.6847 10.0356C18.8935 10.34 18.3281 11.1028 18.3281 12C18.3281 13.1632 19.2743 14.1094 20.4375 14.1094C21.6007 14.1094 22.5469 13.1632 22.5469 12C22.5469 11.0653 21.9318 10.28 21.0882 10.0038C20.9958 7.005 19.2869 4.33692 16.8467 2.8125H19.7344V3.51562C19.7344 3.90427 20.0489 4.21875 20.4375 4.21875H23.2969C23.6855 4.21875 24 3.90427 24 3.51562V0.703125C24 0.314484 23.6855 0 23.2969 0Z"
                                fill="currentColor"
                              />
                              <path
                                d="M16.7835 23.1279C16.4157 22.0025 15.3561 21.1406 14.1093 21.1406H9.89052C8.64365 21.1406 7.58408 22.0025 7.2163 23.1279C7.07451 23.5618 7.44365 24 7.90021 24H16.0996C16.5561 24 16.9253 23.5618 16.7835 23.1279Z"
                                fill="currentColor"
                              />
                              <path
                                d="M18.21 14.4225L12.7031 6.16217V12.8327C13.5198 13.1239 14.1094 13.8971 14.1094 14.8125C14.1094 15.9757 13.1632 16.9219 12 16.9219C10.9369 16.9219 9.89063 15.9757 9.89063 14.8125C9.89063 13.8971 10.4802 13.1239 11.2969 12.8327V6.16217L5.79001 14.4225C5.60391 14.7013 5.64099 15.0728 5.8779 15.3097C7.16869 16.6005 7.99379 18.2634 8.31221 20.0471C8.80065 19.849 9.33188 19.7344 9.89063 19.7344H14.1094C14.6681 19.7344 15.1994 19.8489 15.6878 20.047C16.0062 18.2632 16.8313 16.6005 18.1221 15.3097C18.359 15.0728 18.3961 14.7013 18.21 14.4225Z"
                                fill="currentColor"
                              />
                              <path
                                d="M12 14.1094C11.6121 14.1094 11.2969 14.4246 11.2969 14.8125C11.2969 15.2004 11.6121 15.5156 12 15.5156C12.3879 15.5156 12.7031 15.2004 12.7031 14.8125C12.7031 14.4246 12.3879 14.1094 12 14.1094Z"
                                fill="currentColor"
                              />
                            </g>
                            <defs>
                              <clipPath>
                                <rect
                                  width={24}
                                  height={24}
                                  fill="currentColor"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="tp-slider-exp-item__content">
                          <h4 className="tp-exp-sm-title">
                            Why you should choose us{" "}
                          </h4>
                        </div>
                        <div className="tp-slider-exp-item__service-link">
                          <div className="tp-link">
                            <Link to="/about-us">
                              <svg
                                width={34}
                                height={16}
                                viewBox="0 0 34 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                  fill="#202026"
                                />
                              </svg>
                              <span>About us</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tp-slider-area-end */}
          {/* tp-about-area-start */}
          <div className="tp-about-area ab-area-sapce pt-120 pb-120">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-xl-5 col-lg-5 order-2 order-lg-1 wow tpfadeLeft"
                  data-wow-duration=".7s"
                  data-wow-delay=".5s"
                  style={{ scale: "0.9" }}
                >
                  <div className="tp-ab-wrapper p-relative">
                    <div className="tp-ab-shape-one z-index-3">
                      <img src="assets/img/about/about-shape-1.png" alt="" />
                    </div>
                    <div className="tp-ab-shape-two z-index-3">
                      <img
                        src="assets/img/about/about-circle-shape.png"
                        alt=""
                      />
                    </div>
                    <div className="tp-about-thumb">
                      <img src="assets/img/about/about-img.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-7 col-lg-7 order-1 order-lg-2 wow tpfadeRight"
                  data-wow-duration=".7s"
                  data-wow-delay=".7s"
                  style={{ scale: "0.9" }}
                >
                  <div className="tp-ab-section-title-box">
                    <h4 className="tp-section-subtitle tp-green-color">
                      Who we are?
                    </h4>
                    <h3 className="tp-section-title">
                      We are dynamic team of creative design and development
                    </h3>
                    <p>
                      With our years of experience in offering business
                      solutions and consulting services, we guarantee the best
                      possible protection and assistance.
                    </p>
                    <Link to="/about-us" className="tp-btn">
                      About our Agency
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tp-about-area-end */}
          {/* tp-service-area-start */}
          <OurService />
          {/* tp-service-area-end */}
          {/* tp-case-area-start */}
          <div
            className="tp-case-area case-bg case-space"
            data-background="assets/img/case/case-grident-shape.png"
            style={{ background: `url(${case_grident_bg})` }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div
                  className="col-xl-8 col-12  wow tpfadeUp"
                  data-wow-duration=".7s"
                  data-wow-delay=".3s"
                >
                  <div className="tp-case-section-title-box text-center mb-60">
                    <h4 className="tp-section-subtitle tp-green-color">
                      Check our Work
                    </h4>
                    <h3 className="tp-section-title">
                      Some of our recent case studies
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row gx-20">
                <div
                  className="col-xl-6 col-lg-6  wow tpfadeLeft"
                  data-wow-duration=".7s"
                  data-wow-delay=".5s"
                  style={{ scale: "0.9" }}
                >
                  <div className="tp-case-thumb p-relative fix mb-20">
                    <img src={aanantmarketing} alt="" />
                    <div className="case-icon">
                      <a
                        className="popup-image"
                        href="https://aanantmarketing.com/"
                      >
                        <i className="fas fa-arrow-alt-up" />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-6 col-lg-6  wow tpfadeRight"
                  data-wow-duration=".7s"
                  data-wow-delay=".7s"
                  style={{ scale: "0.9" }}
                >
                  <div className="tp-case-thumb p-relative fix mb-20">
                    <img src={gpgs} alt="" />
                    <div className="case-icon">
                      <a
                        className="popup-image"
                        href="https://gsapg.netlify.app/"
                      >
                        <i className="fas fa-arrow-alt-up" />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-6 col-lg-6  wow tpfadeLeft"
                  data-wow-duration=".7s"
                  data-wow-delay=".9s"
                  style={{ scale: "0.9" }}
                >
                  <div className="tp-case-thumb p-relative fix mb-20">
                    <img src={thesince} alt="" />
                    <div className="case-icon">
                      <a
                        className="popup-image"
                        href="https://thesciein.com/"
                      >
                        <i className="fas fa-arrow-alt-up" />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-6 col-lg-6  wow tpfadeRight"
                  data-wow-duration=".7s"
                  data-wow-delay="1s"
                  style={{ scale: "0.9" }}
                >
                  <div className="tp-case-thumb p-relative fix mb-20">
                    <img src={punseva} alt="" />
                    <div className="case-icon">
                      <a
                        className="popup-image"
                        href="https://punseva-demo.onrender.com/"
                      >
                        <i className="fas fa-arrow-alt-up" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="tp-case-button text-center mt-40">
                    <Link to="/projects" className="tp-btn">
                      all works
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tp-case-area-end */}
          {/* tp-service-area-start */}
          {/* <div
            className="tp-testimonial-area tp-testimonial-space p-relative testimonial-border-shape"
            data-background="assets/img/testimonial/testi-border-shape.png"
            style={{ background: `url(${testi_border_shape})` }}
          >
            <div className="container">
              <div className="row">
                <div
                  className="col-xl-6 col-lg-12 wow tpfadeLeft"
                  data-wow-duration=".7s"
                  data-wow-delay=".5s"
                  style={{ scale: "0.9" }}
                >
                  <div className="service-section-wrapper">
                    <div className="tp-testimonial-section-title-box">
                      <h4 className="tp-section-subtitle tp-yellow-color">
                        Happy customer feedback
                      </h4>
                      <h3 className="tp-section-title">
                        Happy customer feedback
                      </h3>
                    </div>
                    <div className="tp-service-content mb-20">
                      <h4 className="tp-service-sm-title">
                        We love our client and our clients <br /> loved us. see
                        all feedback
                      </h4>
                      <Link className="tp-btn" to="/service">
                        See all Feedback
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12">
                  <div className="row">
                    <div
                      className="col-xl-12 col-lg-6 wow tpfadeUp"
                      data-wow-duration=".7s"
                      data-wow-delay=".7s"
                    >
                      <div className="tp-testimonial-item mb-30">
                        <div className="tp-testimonial-item__icon">
                          <i className="fas fa-quote-right" />
                        </div>
                        <div className="tp-testimonial-item__text">
                          <p>
                            Our office is something we are pleased with. We
                            consider it the little magnet; it is wanting to come
                            here and afterward difficult to leave it.
                          </p>
                        </div>
                        <div className="tp-testimonial-item__wrapper d-flex justify-content-between align-items-center">
                          <div className="tp-testimonial-item__info d-flex align-items-center">
                            <div className="tp-testimonial-item__info-img">
                              <img
                                src="assets/img/testimonial/testi-img-1.png"
                                alt=""
                              />
                            </div>
                            <div className="tp-testimonial-item__info-text">
                              <h4 className="client-name">Wade Warren</h4>
                              <span>Founder of Edardx</span>
                            </div>
                          </div>
                          <div className="tp-testimonial-item__ratting d-none d-md-block">
                            <span>
                              <i className="fas fa-star" />
                            </span>
                            <span>
                              <i className="fas fa-star" />
                            </span>
                            <span>
                              <i className="fas fa-star" />
                            </span>
                            <span>
                              <i className="fas fa-star" />
                            </span>
                            <span>
                              <i className="fas fa-star" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-12 col-lg-6 wow tpfadeUp"
                      data-wow-duration=".7s"
                      data-wow-delay=".9s"
                    >
                      <div className="tp-testimonial-item testimonial-bg-color-2 mb-30">
                        <div className="tp-testimonial-item__icon">
                          <i className="fas fa-quote-right" />
                        </div>
                        <div className="tp-testimonial-item__text">
                          <p>
                            He works very smart always fast and ahead of time.
                            He's also a team player Looking forward to working
                            with him in the future
                          </p>
                        </div>
                        <div className="tp-testimonial-item__wrapper d-flex justify-content-between align-items-center">
                          <div className="tp-testimonial-item__info d-flex align-items-center">
                            <div className="tp-testimonial-item__info-img">
                              <img
                                src="assets/img/testimonial/testi-img-2.png"
                                alt=""
                              />
                            </div>
                            <div className="tp-testimonial-item__info-text">
                              <h4 className="client-name">Marvin McKinney</h4>
                              <span>Founder of Edardx</span>
                            </div>
                          </div>
                          <div className="tp-testimonial-item__ratting d-none d-md-block">
                            <span>
                              <i className="fas fa-star" />
                            </span>
                            <span>
                              <i className="fas fa-star" />
                            </span>
                            <span>
                              <i className="fas fa-star" />
                            </span>
                            <span>
                              <i className="fas fa-star" />
                            </span>
                            <span>
                              <i className="fas fa-star" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-12 col-lg-6 wow tpfadeUp"
                      data-wow-duration=".7s"
                      data-wow-delay="1s"
                    >
                      <div className="tp-testimonial-item testimonial-bg-color-3 mb-30">
                        <div className="tp-testimonial-item__icon">
                          <i className="fas fa-quote-right" />
                        </div>
                        <div className="tp-testimonial-item__text">
                          <p>
                            Sixmart is an Excellent Writer, His magical words
                            added value in growth of our business. Highly
                            Recommended
                          </p>
                        </div>
                        <div className="tp-testimonial-item__wrapper d-flex justify-content-between align-items-center">
                          <div className="tp-testimonial-item__info d-flex align-items-center">
                            <div className="tp-testimonial-item__info-img">
                              <img
                                src="assets/img/testimonial/testi-img-3.png"
                                alt=""
                              />
                            </div>
                            <div className="tp-testimonial-item__info-text">
                              <h4 className="client-name">Savannah Nguyen</h4>
                              <span>Founder of Edardx</span>
                            </div>
                          </div>
                          <div className="tp-testimonial-item__ratting d-none d-md-block">
                            <span>
                              <i className="fas fa-star" />
                            </span>
                            <span>
                              <i className="fas fa-star" />
                            </span>
                            <span>
                              <i className="fas fa-star" />
                            </span>
                            <span>
                              <i className="fas fa-star" />
                            </span>
                            <span>
                              <i className="fas fa-star" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* tp-service-area-end */}
          {/* tp-barnd-area-start */}
          {/* <div className="tp-brand-area brand-space-bottom pb-110">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="tp-brand-title-box pb-60">
                    <h4 className="tp-brand-title">
                      <span>Over 250+</span>
                      <span>Business</span> growing with us
                    </h4>
                  </div>
                </div>
              </div>
              <div
                className="brand-slider-active d-flex align-items-center"
                style={{ justifyContent: "space-between" }}
              >
                <div className="brand-item">
                  <div className="tp-brand-icon">
                    <img src={aanantmarketing} alt="" style={{height:"39px"}} />
                  </div>
                </div>
                <div
                  className="brand-item wow tpfadeUp"
                  data-wow-duration=".7s"
                  data-wow-delay=".3s"
                >
                  <div className="tp-brand-icon">
                    <img src={punseva} alt="" style={{height:"39px"}} />
                  </div>
                </div>
                <div
                  className="brand-item wow tpfadeUp"
                  data-wow-duration=".7s"
                  data-wow-delay=".5s"
                >
                  <div className="tp-brand-icon">
                    <img src={gprobo} alt="" style={{height:"39px"}}/>
                  </div>
                </div>
                <div
                  className="brand-item wow tpfadeUp"
                  data-wow-duration=".7s"
                  data-wow-delay=".7s"
                >
                  <div className="tp-brand-icon">
                    <img src={thesince} alt="" style={{height:"39px"}}/>
                  </div>
                </div>
                <div
                  className="brand-item wow tpfadeUp"
                  data-wow-duration=".7s"
                  data-wow-delay=".9s"
                >
                  <div className="tp-brand-icon">
                    <img src="assets/img/brand/brand-5.png" alt="" />
                  </div>
                </div>
                <div
                  className="brand-item wow tpfadeUp"
                  data-wow-duration=".7s"
                  data-wow-delay=".9s"
                >
                  <div className="tp-brand-icon">
                    <img src="assets/img/brand/brand-6.png" alt="" />
                  </div>
                </div>
                <div
                  className="brand-item wow tpfadeUp"
                  data-wow-duration=".7s"
                  data-wow-delay="1s"
                >
                  <div className="tp-brand-icon">
                    <img src="assets/img/brand/brand-6.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* tp-barnd-area-end */}
          {/* tp-blog-area-start */}
          {/* <div
            className="tp-blog-area blog-grident-bg blog-space"
            data-background="assets/img/blog/blog-gridient-shape.png"
            style={{ background: `url(${blog_grident_bg})` }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6">
                  <div className="tp-blog-section-title-box text-center mb-60">
                    <h4 className="tp-section-subtitle tp-green-color">
                      Our Blog Article
                    </h4>
                    <h3 className="tp-section-title">
                      Read our Latest stories{" "}
                    </h3>
                  </div>
                </div>
                <div className="row gx-20">
                  <div
                    className="col-xl-6 col-lg-6 wow tpfadeLeft"
                    data-wow-duration=".7s"
                    data-wow-delay=".4s"
                    style={{ scale: "0.9" }}
                  >
                    <div className="tp-blog-item mb-30">
                      <div className="tp-blog-item__thumb fix">
                        <a href="blog-details.html">
                          <img src="assets/img/blog/blog-1.jpg" alt="" />
                        </a>
                      </div>
                      <div className="tp-blog-item__tag">
                        <span>Tutorials</span>
                        <span className="tag-color-green">
                          February. 20.2022
                        </span>
                      </div>
                      <div className="tp-blog-item__blog-title">
                        <h4 className="tp-blog-title-lg">
                          <a href="blog-details.html">
                            How to Increase Your ROI Through scientific SEM?
                          </a>
                        </h4>
                      </div>
                      <div className="tp-blog-item__button">
                        <div className="tp-link tp-link-green">
                          <a href="blog-details.html">
                            <svg
                              width={34}
                              height={16}
                              viewBox="0 0 34 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                fill="#202026"
                              />
                            </svg>
                            <span>Read MORe</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 wow tpfadeRight"
                    data-wow-duration=".7s"
                    data-wow-delay=".7s"
                    style={{ scale: "0.9" }}
                  >
                    <div className="tp-blog-item mb-30">
                      <div className="tp-blog-item__thumb fix">
                        <a href="blog-details.html">
                          <img src="assets/img/blog/blog-2.jpg" alt="" />
                        </a>
                      </div>
                      <div className="tp-blog-item__tag">
                        <span>Design</span>
                        <span className="tag-color-green">
                          February. 20.2022
                        </span>
                      </div>
                      <div className="tp-blog-item__blog-title">
                        <h4 className="tp-blog-title-lg">
                          <a href="blog-details.html">
                            ECommerce Marketing Statistics You Should Kno
                          </a>
                        </h4>
                      </div>
                      <div className="tp-blog-item__button">
                        <div className="tp-link tp-link-green">
                          <a href="blog-details.html">
                            <svg
                              width={34}
                              height={16}
                              viewBox="0 0 34 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                fill="#202026"
                              />
                            </svg>
                            <span>Read MORe</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* tp-blog-area-end */}
          {/* tp-social-area-start */}
          {/* <div className="tp-social-area social-space-bottom fix">
            <div className="container-fluid p-0">
              <div className="row g-0">
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <a href="#">
                    <div className="tp-social-item">
                      <span>
                        <i className="fab fa-facebook-f" /> Facebook
                      </span>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <a href="#">
                    <div className="tp-social-item tp-youtube">
                      <span>
                        <i className="fab fa-youtube" /> youtube
                      </span>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <a href="#">
                    <div className="tp-social-item tp-behance">
                      <span>
                        <i className="fab fa-behance" /> behance
                      </span>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <a href="#">
                    <div className="tp-social-item tp-dribble">
                      <span>
                        <i className="fab fa-dribbble" /> dribbble
                      </span>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <a href="#">
                    <div className="tp-social-item tp-twitter">
                      <span>
                        <i className="fab fa-twitter" /> twitter
                      </span>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <a href="#">
                    <div className="tp-social-item tp-linkedin">
                      <span>
                        <i className="fab fa-linkedin" />
                        linkedin
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          {/* tp-social-area-end */}
          <ContactUsForm />
        </main>
      </>
    </section>
  );
};

export default Home;
