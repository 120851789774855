import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../../assets/logo.png";
import "./header.style.scss"

const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <header>
        {/* tp-header-area-start */}
        <div
          id="header-sticky"
          className="tp-header-area header-pl-pr header-transparent header-border-bottom"
        >
          <div className="container-fluid">
            <div className="row g-0 align-items-center">
              <div className="col-xl-2 col-lg-2 col-md-4 col-6">
                <div className="tp-logo tp-logo-border">
                  <Link to="/" title="SNM Solution">

                    <img src={logo} alt="" />
                  </Link>
              
                </div>
              </div>
              <div className="col-xl-10 col-lg-10 col-md-8 col-6 d-flex justify-content-end">
                <div className="tp-main-menu d-none d-xl-block">
                  <nav id="mobile-menu">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About</Link>
                      </li>
                      {/* <li>
                        <a href="case-details.html">Our Project</a>
                        <ul className="submenu">
                          <li>
                            <Link to="/projects">Projects</Link>
                          </li>
                          <li>
                            <Link to="/project-detail">Project Details</Link>
                          </li>
                        </ul>
                      </li> */}
                      <li>
                        <Link to="/service">Service</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact</Link>
                      </li>
                      {/* <li>
                        <a href="#">Pages</a>
                        <ul className="submenu">
                          <li>
                            <Link to="/blogs">Blog Sidebar</Link>
                          </li>
                          <li>
                            <Link to="blog-detail">Blog Details</Link>
                          </li>
                          <li>
                            <Link to="/team">Team</Link>
                          </li>
                          

                          <li>
                            <Link to="/price">Price</Link>
                          </li>
                          <li>
                            <Link to="/faq">Faq</Link>
                          </li>

                          <li>
                            <Link to="/contact-us">Contact</Link>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
                  </nav>
                </div>
                <div className="tp-header-right">
                  <ul>
                    {/* <li className=" d-none d-md-inline-block search-wrapper">
                      <a className="tp-search-box" href="javascript:void(0)">
                        <i className="tp-search-toggle fal fa-search" />
                        <i className="search-close  far fa-times" />
                      </a>
                      <div className="tp-search-form p-relative">
                        <form action="#">
                          <input type="text" placeholder="Search ..." />
                          <button type="submit">
                            <i className="far fa-search" />
                          </button>
                        </form>
                      </div>
                    </li>
                    <li className="d-none d-md-inline-block">
                      <a href="cart.html">
                        <i className="fal fa-shopping-basket" />
                        <span>03</span>
                      </a>
                      <div className="minicart">
                        <div>
                          <div className="cart-img">
                            <a href="product-details.html">
                              <img src="assets/img/shop//shop-2.jpg" alt="" />
                            </a>
                          </div>
                          <div className="cart-content">
                            <h3>
                              <a href="product-details.html">Mobile - phone</a>
                            </h3>
                            <div className="cart-price">
                              <span className="new">$ 22.9</span>
                              <span>
                                <del>$39.9</del>
                              </span>
                            </div>
                          </div>
                          <div className="del-icon">
                            <a href="#">
                              <i className="far fa-trash-alt" />
                            </a>
                          </div>
                        </div>
                        <div>
                          <div className="cart-img">
                            <a href="product-details.html">
                              <img src="assets/img/shop/shop-1.jpg" alt="" />
                            </a>
                          </div>
                          <div className="cart-content">
                            <h3>
                              <a href="product-details.html">
                                Gothelf learn UX
                              </a>
                            </h3>
                            <div className="cart-price">
                              <span className="new">$ 22.9</span>
                              <span>
                                <del>$39.9</del>
                              </span>
                            </div>
                          </div>
                          <div className="del-icon">
                            <a href="#">
                              <i className="far fa-trash-alt" />
                            </a>
                          </div>
                        </div>
                        <div>
                          <div className="total-price">
                            <span className="f-left">Total:</span>
                            <span className="f-right">$100.0</span>
                          </div>
                        </div>
                        <div>
                          <div className="checkout-link">
                            <a
                              className="tp-btn-orange-radius w-100"
                              href="cart.html"
                            >
                              Shopping Cart
                            </a>
                            <a
                              className="tp-btn-radius-2 w-100"
                              href="checkout.html"
                            >
                              Checkout
                            </a>
                          </div>
                        </div>
                      </div>
                    </li> */}
                    <li>
                      <a className="tp-menu-bar" onClick={() => handleShow()}>
                        <i className="fas fa-bars" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* tp-header-area-end */}
      </header>
      <Outlet />
      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        {/* <Offcanvas.Header closeButton>
          {" "}
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>{" "}
        </Offcanvas.Header> */}
        <Offcanvas.Body>
          <div className="tp-offcanvas-area">
            <div className="tpoffcanvas">
              <div className="tpoffcanvas__close-btn">
                <button className="close-btn" onClick={() => handleClose()}>
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="tpoffcanvas__logo pt-50">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>
              <div className="tpoffcanvas__text">
                {/* <p>
                  Suspendisse interdum consectetur libero id. Fermentum leo vel
                  orci porta non. Euismod viverra nibh cras pulvinar suspen.
                </p> */}
              </div>
              <nav className="mean-nav">
                <ul>
                <li>
                        <Link to="/" onClick={()=>handleClose()}>Home</Link>
                      </li>
                      <li>
                        <Link to="/about-us" onClick={()=>handleClose()}>About</Link>
                      </li>
                      {/* <li>
                        <a href="case-details.html">Our Project</a>
                        <ul className="submenu">
                          <li>
                            <Link to="/projects">Projects</Link>
                          </li>
                          <li>
                            <Link to="/project-detail">Project Details</Link>
                          </li>
                        </ul>
                      </li> */}
                      <li>
                        <Link to="/service" onClick={()=>handleClose()}>Service</Link>
                      </li>
                      <li>
                        <Link to="/contact-us" onClick={()=>handleClose()}>Contact</Link>
                      </li>
                </ul>
              </nav>

              <div className="mobile-menu" />
              <div className="tpoffcanvas__info">
                <h3 className="offcanva-title">Get In Touch</h3>
                <div className="tp-info-wrapper mb-20 d-flex align-items-center">
                  <div className="tpoffcanvas__info-icon">
                    <a href="#">
                      <i className="fal fa-envelope" />
                    </a>
                  </div>
                  <div className="tpoffcanvas__info-address">
                    <span>Email</span>
                    <a href="maito:meetvelani2728@gmail.com">
                      <span
                        className="__cf_email__"
                        data-cfemail="f59d9099999ab58c9a808798949c99db969a98"
                      >
                        meetvelani2728@gmail.com
                      </span>
                    </a>
                  </div>
                </div>
                <div className="tp-info-wrapper mb-20 d-flex align-items-center">
                  <div className="tpoffcanvas__info-icon">
                    <a href="#">
                      <i className="fal fa-phone-alt" />
                    </a>
                  </div>
                  <div className="tpoffcanvas__info-address">
                    <span>Phone</span>
                    <a href="tel:(91) 9173848036"> +91 9173848036</a>
                  </div>
                </div>
                <div className="tp-info-wrapper mb-20 d-flex align-items-center">
                  <div className="tpoffcanvas__info-icon">
                    <a href="#">
                      <i className="fas fa-map-marker-alt" />
                    </a>
                  </div>
                  <div className="tpoffcanvas__info-address">
                    <span>Location</span>
                    <a
                      href="https://www.google.com/maps/place/Mighty+Scales+Infra+LLP+(Pantheon)/@23.2341825,69.6450674,17z/data=!3m1!4b1!4m6!3m5!1s0x39511f268e4db2df:0xf537219b3c07b715!8m2!3d23.2341776!4d69.6476477!16s%2Fg%2F11kb_8glmp?entry=ttu"
                      target="_blank"
                    >
                      office no 215, pantheon, mirjapar road, bhuj, kutch,
                      370001
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="tpoffcanvas__social">
                <div className="social-icon">
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                  <a href="#">
                    <i className="fab fa-facebook-square" />
                  </a>
                  <a href="#">
                    <i className="fab fa-dribbble" />
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
