import React from "react";
import "./footer.style.scss";
// import Logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
// import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { IoIosPhonePortrait } from "react-icons/io";
import { MdMailOutline } from "react-icons/md";
import { HiMiniReceiptRefund } from "react-icons/hi2";
import footer_bg from "../../assets/img/footer/footer-bg.jpg";
import { useForm } from "react-hook-form";
import { useStateValue } from "../../StateProvider";
import { db } from "../../utils/firebase";
import toast from "react-hot-toast";
import { collection, addDoc } from "firebase/firestore";

const Footer = () => {
  const [, dispatch] = useStateValue();
  const subscribe = async (data) => {
    dispatch({
      type: "SET_LOADING_STATUS",
      status: true,
    });
    try {
      const docRef = await addDoc(collection(db, "subscribe"), {
        email: data,
      });
      toast.success("success");
      reset();
    } catch (e) {
      toast.error("somthing went wrong.");
    }
    dispatch({
      type: "SET_LOADING_STATUS",
      status: false,
    });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  return (
    <footer>
      {/* tp-footer-area-start */}
      {/* <div className="tp-social-area social-space-bottom fix">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <a href="#">
                <div className="tp-social-item">
                  <span>
                    <i className="fab fa-facebook-f" /> Facebook
                  </span>
                </div>
              </a>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <a href="#">
                <div className="tp-social-item tp-youtube">
                  <span>
                    <i className="fab fa-youtube" /> youtube
                  </span>
                </div>
              </a>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <a href="#">
                <div className="tp-social-item tp-behance">
                  <span>
                    <i className="fab fa-behance" /> behance
                  </span>
                </div>
              </a>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <a href="#">
                <div className="tp-social-item tp-dribble">
                  <span>
                    <i className="fab fa-dribbble" /> dribbble
                  </span>
                </div>
              </a>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <a href="#">
                <div className="tp-social-item tp-twitter">
                  <span>
                    <i className="fab fa-twitter" /> twitter
                  </span>
                </div>
              </a>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <a href="#">
                <div className="tp-social-item tp-linkedin">
                  <span>
                    <i className="fab fa-linkedin" />
                    linkedin
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="tp-footer-area footer-bg pt-130"
        data-background="assets/img/footer/footer-bg.jpg"
        style={{ background: `url(${footer_bg})` }}
      >
        <div className="container">
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-xl-2 col-lg-2 col-md-3 col-6 mb-30">
              <div className="tp-footer-widget z-index-3">
                <div className="tp-footer-widget__title">
                  <h4 className="tp-footer-title">Quik Links</h4>
                </div>
                <div className="tp-footer-widget__list">
                  <ul>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/about-us"></Link>
                    </li>
                    <li>
                      <Link to="/contact-us"> Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/projects">Our Works</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-2 col-lg-2 col-md-3 col-6 mb-30">
              <div className="tp-footer-widget z-index-3">
                <div className="tp-footer-widget__title">
                  <h4 className="tp-footer-title">Product</h4>
                </div>
                <div className="tp-footer-widget__list">
                  <ul>
                    <li>
                      <a href="index.html">Landing Page</a>
                    </li>
                    <li>
                      <a href="#">Popup Builder</a>
                    </li>
                    <li>
                      <a href="#">Web-design</a>
                    </li>
                    <li>
                      <a href="#">Content</a>
                    </li>
                    <li>
                      <a href="#">Integrations</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-xl-3 col-lg-2 col-md-3 col-6 mb-30">
              <div className="tp-footer-widget footer-space-one z-index-3">
                <div className="tp-footer-widget__title">
                  <h4 className="tp-footer-title">Service</h4>
                </div>
                <div className="tp-footer-widget__list">
                  <ul>
                    <li>
                      <Link to="/service-detail/web-development">
                        Web Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/service-detail/web-design">Web Design</Link>
                    </li>
                    <li>
                      <Link to="/service-detail/mobile-development">
                        Mobile app Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/service-detail/seo">SEO</Link>
                    </li>
                    <li>
                      <Link to="/service-detail/custom-solution">
                        Any kind Custom Solution
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-2 col-lg-2 col-md-3 col-6 mb-30">
              <div className="tp-footer-widget footer-space-two z-index-3">
                <div className="tp-footer-widget__title">
                  <h4 className="tp-footer-title">Resources</h4>
                </div>
                <div className="tp-footer-widget__list">
                  <ul>
                    <li>
                      <a href="#">Academy</a>
                    </li>
                    <li>
                      <a href="blog-details.html">Blog</a>
                    </li>
                    <li>
                      <a href="#">Themes</a>
                    </li>
                    <li>
                      <a href="#">Hosting</a>
                    </li>
                    <li>
                      <a href="#">Developers</a>
                    </li>
                    <li>
                      <a href="contact.html">Support</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-2 col-lg-2 col-md-3 col-6 mb-30">
              <div className="tp-footer-widget footer-space-three z-index-3">
                <div className="tp-footer-widget__title">
                  <h4 className="tp-footer-title">Product</h4>
                </div>
                <div className="tp-footer-widget__list">
                  <ul>
                    <li>
                      <a href="index.html">Landing Page</a>
                    </li>
                    <li>
                      <a href="#">Popup Builder</a>
                    </li>
                    <li>
                      <a href="#">Web-design</a>
                    </li>
                    <li>
                      <a href="#">Content</a>
                    </li>
                    <li>
                      <a href="#">Integrations</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-30">
              <div className="tp-footer-widget z-index-3">
                <div className="tp-footer-widget__title">
                  <h4 className="tp-footer-title">Contact Us</h4>
                </div>
                <div className="tp-footer-widget__list">
                  <ul className="footer-position">
                    <li>
                      <a
                        href="https://www.google.com/maps/place/Mighty+Scales+Infra+LLP+(Pantheon)/@23.2341825,69.6450674,17z/data=!3m1!4b1!4m6!3m5!1s0x39511f268e4db2df:0xf537219b3c07b715!8m2!3d23.2341776!4d69.6476477!16s%2Fg%2F11kb_8glmp?entry=ttu"
                        target="_blank"
                      >
                        <span>
                          <i className="fal fa-map-marker-alt" />
                        </span>
                        Office no 215, Pantheon, Mirjapar Road, Bhuj, kachchh,
                        370001.
                      </a>
                    </li>
                    <li style={{ marginBottom: "30px" }}>
                      <a href="/cdn-cgi/l/email-protection#51222421213e2325113738363c30">
                        <span>
                          <i className="fal fa-envelope-open" />
                        </span>
                        <span
                          className="__cf_email__"
                          data-cfemail="32414742425d404672545b555f531c515d5f"
                          style={{ marginLeft: "40px", marginBottom: "15px" }}
                        >
                          info@snmsolution.in
                        </span>
                      </a>
                    </li>
                    <li style={{ paddingTop: "3px" }}>
                      <a href="tel:(91) 9173848036">
                        <span>
                          <i className="fal fa-phone-alt" />
                        </span>
                        +91 9173848036
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tp-newsletter-wrapper z-index-3">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="tp-newsletter">
                      <div className="tp-newsletter__title">
                        <h4 className="tp-newsletter-title">
                          Get latest updates
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="tp-newsletter">
                      <div className="tp-newsletter__input p-relative">
                        <form
                          onSubmit={handleSubmit((data) => subscribe(data))}
                        >
                          <input
                            type="email"
                            placeholder="Enter your mail"
                            {...register("email", {
                              required: true,
                            })}
                            required
                          />
                          <button className="tp-btn-yellow" type="submit">
                            Subscribe <i className="far fa-arrow-right" />
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copy-right-wrapper z-index-3">
            <div className="row">
              <div className="col-xl-6 col-lg-7 col-12">
                <div className="copyright-left text-center text-lg-start">
                  <p>©Copy RIght @2024 All Rights Reserved - SNM Solution</p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-5 col-12">
                <div className="copyright-right-side text-center text-lg-end">
                  {/* <ul>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#">Terms of Use</a>
                    </li>
                    <li>
                      <a href="#">Sales and Refunds</a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* tp-footer-area-end */}
      <button
        className="scroll-top scroll-to-target"
        data-target="html"
        onClick={() => window.scrollTo(0, 0)}
      >
        <i className="far fa-angle-double-up" />
      </button>
    </footer>
  );
};

export default Footer;
