import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./routes/home/home.component";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toaster } from "react-hot-toast";
import { useStateValue } from "./StateProvider";
import Header from "./components/header/header.component";
import Loader from "./components/loader/loader.component";
import Footer from "./components/footer/footer.component";
import AboutUs from "./routes/aboutUs/aboutUs.component";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PageNotFound from "./routes/pageNotFound/pageNotFound.component";
import Blogs from "./routes/blogs/blogs.component";
import BlogDetail from "./routes/blogDetail/blogDetail.component";
import Projects from "./routes/projects/projects.component";
import ProjectDetail from "./routes/projectDetail/projectDetail.component";
import ContactUs from "./routes/contactUs/contactUs.component";
import FAQ from "./routes/FAQ/FAQ.component";
import Price from "./routes/price/price.component";
import Service from "./routes/service/service.component";
import ServiceDetail from "./routes/serviceDetail/serviceDetail.component";
import Team from "./routes/team/team.component";

const App = () => {
  const [{ isLoading }, dispatch] = useStateValue();

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />

      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Footer />
              </>
            }
          >
            <Route index element={<Home />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="blogs" element={<Blogs />} />
            <Route path="blog-detail" element={<BlogDetail />} />
            <Route path="projects" element={<Projects />} />
            <Route path="project-detail" element={<ProjectDetail />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="price" element={<Price />} />
            <Route path="service" element={<Service />} />
            <Route path="service-detail/:servieType" element={<ServiceDetail />} />
            <Route path="team" element={<Team />} />
          </Route>
          <Route path="*" element={<><Header /> <PageNotFound /><Footer /></>} />
        </Routes>
        {isLoading && <Loader />}
      </BrowserRouter>
    </>
  );
};

export default App;
