import React from "react";
import "./loader.style.scss";

function Loader(props) {
  return (
    <div id="preloader">
        <div class="preloader">
            <span></span>
            <span></span>
        </div>
    </div>
  );
}

export default Loader;
