import React from "react";
import { Link } from "react-router-dom";

function PageNotFound(props) {
  return (
    <div>
      <>
        <section
          className="breadcrumb__area breadcrumb-height include-bg p-relative "
          style={{
            background:
              "url(https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/breadcurmb.jpg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="breadcrumb__content">
                  <h3 className="breadcrumb__title">Page not Found</h3>
                  <div className="breadcrumb__list p-0 mt-15">
                    {/* Breadcrumb NavXT 7.1.0 */}
                    <span property="itemListElement" typeof="ListItem">
                      <Link to="/">
                        <span property="name">Home </span>
                      </Link>
                      <meta property="position" content={1} />
                    </span>
                    <span className="dvdr">
                      <i className="fa fa-angle-right" />
                    </span>
                    <span property="itemListElement" typeof="ListItem">
                      <a
                        property="item"
                        typeof="WebPage"
                        title="Go to 404."
                        href=""
                        className="404 current-item"
                        aria-current="page"
                      >
                        <span property="name">404</span>
                      </a>
                      <meta property="position" content={2} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="error__area pt-200 pb-200 ">
          <div className="container">
            <div className="row">
              <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                <div className="error__item text-center">
                  <div className="error__thumb mb-20">
                    <h2>404</h2>
                  </div>
                  <div className="error__content">
                    <h3 className="error__title">Page not found</h3>
                    <p>
                      Oops! The page you are looking for does not exist. It
                      might have been moved or deleted.
                    </p>

                    <Link className="tp-btn" to="/">
                      Back To Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </div>
  );
}

export default PageNotFound;
