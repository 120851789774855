import React from "react";
import Slider from "react-slick";
import "./blogDetail.style.scss";
import { Link } from "react-router-dom";

const BlogDetail = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <section
        className="breadcrumb__area breadcrumb-height include-bg p-relative "
        style={{
          background:
            "url(https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/breadcurmb.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h3 className="breadcrumb__title">
                  Building A Retro Draggable Web Component With Lit ar not
                  working
                </h3>
                <div className="breadcrumb__list p-0 mt-15">
                  {/* Breadcrumb NavXT 7.1.0 */}
                  <span property="itemListElement" typeof="ListItem">
                    <a
                      property="item"
                      typeof="WebPage"
                      title="Go to Sixart ."
                      href="https://data.themeim.com/wp/sixart"
                      className="home"
                    >
                      <span property="name">Sixart </span>
                    </a>
                    <meta property="position" content={1} />
                  </span>
                  <span className="dvdr">
                    <i className="fa fa-angle-right" />
                  </span>
                  <span property="itemListElement" typeof="ListItem">
                    <a
                      property="item"
                      typeof="WebPage"
                      title="Go to Blog."
                      href="https://data.themeim.com/wp/sixart/blog/"
                      className="post-root post post-post"
                    >
                      <span property="name">Blog</span>
                    </a>
                    <meta property="position" content={2} />
                  </span>
                  <span className="dvdr">
                    <i className="fa fa-angle-right" />
                  </span>
                  <span property="itemListElement" typeof="ListItem">
                    <a
                      property="item"
                      typeof="WebPage"
                      title="Go to the PHOTOGRAPHY Category archives."
                      href="https://data.themeim.com/wp/sixart/category/photography/"
                      className="taxonomy category"
                    >
                      <span property="name">PHOTOGRAPHY</span>
                    </a>
                    <meta property="position" content={3} />
                  </span>
                  <span className="dvdr">
                    <i className="fa fa-angle-right" />
                  </span>
                  <span property="itemListElement" typeof="ListItem">
                    <span
                      property="name"
                      className="post post-post current-item"
                    >
                      Building A Retro Draggable Web Component With Lit ar not
                      working
                    </span>
                    <meta
                      property="url"
                      content="https://data.themeim.com/wp/sixart/building-a-retro-draggable-web-component-with-lit-ar-not-working/"
                    />
                    <meta property="position" content={4} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tp-blog-area postbox__area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="postbox__wrapper postbox__details">
                <article
                  id="post-181"
                  className="postbox__item format-image transition-3 post-181 post type-post status-publish has-post-thumbnail hentry category-photography category-statergy category-ui-ux-deisgn category-web-development tag-apps tag-book tag-data post_format-post-format-image"
                >
                  <div className="postbox__content">
                    <p>
                      <img
                        width={843}
                        height={402}
                        src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details.jpg"
                        className="img-responsive w-100 wp-post-image"
                        alt=""
                        decoding="async"
                        fetchpriority="high"
                        srcSet="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details.jpg 843w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-600x286.jpg 600w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-300x143.jpg 300w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-768x366.jpg 768w"
                        sizes="(max-width: 843px) 100vw, 843px"
                      />
                    </p>
                    {/* blog meta */}
                    <div className="postbox__meta">
                      <span>
                        <a href="https://data.themeim.com/wp/sixart/author/sixart/">
                          <i className="fal fa-user-circle" /> sixart{" "}
                        </a>
                      </span>
                      <span>
                        <a href="https://data.themeim.com/wp/sixart/2022/10/12/">
                          <i className="fal fa-clock" /> October 12, 2022
                        </a>
                      </span>
                      <span>
                        <a href="https://data.themeim.com/wp/sixart/building-a-retro-draggable-web-component-with-lit-ar-not-working/#respond">
                          <i className="fal fa-comment-alt-lines" /> No Comments
                        </a>
                      </span>
                    </div>
                    <div className="postbox__text">
                      <p>
                        Nancy boy Charles down the pub get stuffed mate easy
                        peasy brown bread car boot squiffy loo, blimey arse over
                        tit it’s your round cup of char horse play chimney pot
                        old. Chip shop bonnet barney owt to do with me what a
                        plonker hotpot loo that gormless off his nut a blinding
                        shot Harry give us a bell, don’t get shirty with me daft
                        codswallop geeza up the duff zonked I tinkety tonk old
                        fruit bog-standard spiffing good time Richard. Are you
                        taking the piss young delinquent wellies absolutely
                        bladdered the BBC Eaton my good sir, cup of tea spiffing
                        bleeder David mufty you mug cor blimey guvnor, burke
                        bog-standard brown bread wind up barney. Spend a penny a
                        load of old tosh get stuffed mate I don’t want.
                      </p>
                      <blockquote>
                        <p>
                          These types of conversations are very repetitive, and
                          even when a decision is made, they don’t feel like
                          time with the team is well spent. There are so many
                          questions to be answered.
                          <br />
                          <cite>James T. Eggert</cite>
                        </p>
                      </blockquote>
                      <p>
                        Cheeky bugger cracking goal starkers lemon squeezy lost
                        the plot pardon me no biggie the BBC burke gosh boot so
                        I said wellies, zonked a load of old tosh bodge barmy
                        skive off he legged it morish spend a penny my good sir
                        wind up hunky-dory. Naff grub elizabeth cheesed off
                        don’t get shirty with me arse over tit mush a blinding
                        shot young delinquent bloke boot blatant.
                      </p>
                      <p>
                        <img
                          decoding="async"
                          className="alignnone wp-image-57 size-full"
                          src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3.jpg"
                          alt=""
                          width={850}
                          height={430}
                        />
                      </p>
                      <h3>Sixart is the only theme you will ever need</h3>
                      <p>
                        Are you taking the piss young delinquent wellies
                        absolutely bladdered the Eaton my good sir, cup of tea
                        spiffing bleeder David mufty you mug cor blimey guvnor,
                        burke bog-standard brown bread wind up barney. Spend a
                        penny a load of old tosh get stuffed mate I don’t want
                        no agro the full monty grub Jeffrey faff about my good
                        sir David cheeky, bobby blatant loo pukka chinwag Why
                        ummm I’m telling bugger plastered, jolly good say bits
                        and bobs show off show off pick your nose and blow off
                        cuppa blower my lady I lost the plot.
                      </p>
                      <p>
                        Cheeky bugger cracking goal starkers lemon squeezy lost
                        the plot pardon me no biggie the BBC burke gosh boot so
                        I said wellies, zonked a load of old tosh bodge barmy
                        skive off he legged it morish spend a penny my good sir
                        wind up hunky-dory. Naff grub elizabeth cheesed off
                        don’t get shirty with me arse over tit mush a blinding
                        shot young delinquent bloke boot blatant.
                      </p>
                    </div>
                  </div>
                </article>
                <div className="postbox__social-wrapper ">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12">
                      <div className="postbox__tag tagcloud">
                        <span>Tags : </span>
                        <a
                          href="https://data.themeim.com/wp/sixart/tag/apps/"
                          rel="tag"
                        >
                          APPS
                        </a>{" "}
                        <a
                          href="https://data.themeim.com/wp/sixart/tag/book/"
                          rel="tag"
                        >
                          BOOK
                        </a>{" "}
                        <a
                          href="https://data.themeim.com/wp/sixart/tag/data/"
                          rel="tag"
                        >
                          DATA
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="comments" className="blog-post-comment">
                  <div id="respond" className="comment-respond">
                    <h3 id="reply-title" className="comment-reply-title">
                      Leave a Reply{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href="/wp/sixart/building-a-retro-draggable-web-component-with-lit-ar-not-working/#respond"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3>
                    <form
                      action="https://data.themeim.com/wp/sixart/wp-comments-post.php"
                      method="post"
                      id="commentform"
                      className="comment-form"
                    >
                      <p className="comment-notes">
                        <span id="email-notes">
                          Your email address will not be published.
                        </span>{" "}
                        <span className="required-field-message">
                          Required fields are marked{" "}
                          <span className="required">*</span>
                        </span>
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="postbox__comment-input">
                            <input
                              placeholder="Enter Name"
                              id="author"
                              className="tp-form-control"
                              name="author"
                              type="text"
                              defaultValue=""
                              size={30}
                              aria-required="true"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="postbox__comment-input">
                            <input
                              placeholder="Enter Email"
                              id="email"
                              name="email"
                              className="tp-form-control"
                              type="email"
                              defaultValue=""
                              size={30}
                              aria-required="true"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="postbox__comment-input">
                            <input
                              placeholder="Enter Website"
                              id="url"
                              name="url"
                              className="tp-form-control"
                              type="url"
                              defaultValue=""
                              size={30}
                            />
                          </div>
                        </div>
                      </div>
                      <p className="comment-form-cookies-consent">
                        <input
                          id="wp-comment-cookies-consent"
                          name="wp-comment-cookies-consent"
                          type="checkbox"
                          defaultValue="yes"
                        />{" "}
                        <label htmlFor="wp-comment-cookies-consent">
                          Save my name, email, and website in this browser for
                          the next time I comment.
                        </label>
                      </p>
                      <div className="row post-input">
                        <div className="col-md-12 ">
                          <div className="postbox__comment-input">
                            <textarea
                              className="tp-form-control msg-box"
                              placeholder="Enter Your Comments"
                              id="comment"
                              name="comment"
                              cols={45}
                              rows={8}
                              aria-required="true"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div>
                      <p className="form-submit"></p>
                      <div className="col-xl-12">
                        <button className="tp-btn" type="submit">
                          Post Comment{" "}
                        </button>
                      </div>{" "}
                      <input
                        type="hidden"
                        name="comment_post_ID"
                        defaultValue={181}
                        id="comment_post_ID"
                      />
                      <input
                        type="hidden"
                        name="comment_parent"
                        id="comment_parent"
                        defaultValue={0}
                      />
                      <p />
                    </form>
                  </div>
                  {/* #respond */}
                </div>
                {/* #comments */}
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4">
              <div className="blog__sidebar sidebar__wrapper">
                <div
                  id="search-2"
                  className="sidebar__widget mb-40 widget_search"
                >
                  <h3 className="sidebar__widget-title">Search Here</h3>
                  <div className="sidebar__widget-content">
                    <div className="sidebar__search">
                      <form
                        action="https://data.themeim.com/wp/sixart/"
                        method="get"
                      >
                        <div className="sidebar__search-input-2">
                          <input
                            type="text"
                            defaultValue=""
                            required=""
                            name="s"
                            placeholder="Search your keyword..."
                          />
                          <button type="submit">
                            <i className="far fa-search" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  id="tp-latest-posts-2"
                  className="sidebar__widget mb-40 widget_tp-latest-posts"
                >
                  <h3 className="sidebar__widget-title"> Recent Post </h3>
                  <div className="sidebar__widget-content">
                    <div className="sidebar__post rc__post">
                      <div className="rc__post mb-20 d-flex">
                        <div className="rc__post-thumb mr-20">
                          <a href="https://data.themeim.com/wp/sixart/building-a-retro-draggable-web-component-with-lit-ar-not-working/">
                            <img
                              width={100}
                              height={48}
                              src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details.jpg"
                              className="attachment-sixart-widget-blog-1 size-sixart-widget-blog-1 wp-post-image"
                              alt=""
                              decoding="async"
                              loading="lazy"
                              srcSet="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details.jpg 843w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-600x286.jpg 600w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-300x143.jpg 300w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-768x366.jpg 768w"
                              sizes="(max-width: 100px) 100vw, 100px"
                            />{" "}
                          </a>
                        </div>
                        <div className="rc__post-content">
                          <div className="rc__meta">
                            <span>October 12, 2022</span>
                          </div>
                          <h3 className="rc__post-title">
                            <a href="https://data.themeim.com/wp/sixart/building-a-retro-draggable-web-component-with-lit-ar-not-working/">
                              Building A Retro Draggable Web
                            </a>
                          </h3>
                        </div>
                      </div>
                      <div className="rc__post mb-20 d-flex">
                        <div className="rc__post-thumb mr-20">
                          <a href="https://data.themeim.com/wp/sixart/meet-smart-interface-design-patterns-checklists-with-166-cards/">
                            <img
                              width={100}
                              height={48}
                              src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-2.jpg"
                              className="attachment-sixart-widget-blog-1 size-sixart-widget-blog-1 wp-post-image"
                              alt=""
                              decoding="async"
                              loading="lazy"
                              srcSet="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-2.jpg 843w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-2-600x286.jpg 600w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-2-300x143.jpg 300w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-2-768x366.jpg 768w"
                              sizes="(max-width: 100px) 100vw, 100px"
                            />{" "}
                          </a>
                        </div>
                        <div className="rc__post-content">
                          <div className="rc__meta">
                            <span>October 12, 2022</span>
                          </div>
                          <h3 className="rc__post-title">
                            <a href="https://data.themeim.com/wp/sixart/meet-smart-interface-design-patterns-checklists-with-166-cards/">
                              Meet Smart Interface Design Patterns
                            </a>
                          </h3>
                        </div>
                      </div>
                      <div className="rc__post mb-20 d-flex">
                        <div className="rc__post-thumb mr-20">
                          <a href="https://data.themeim.com/wp/sixart/boost-your-skills-online-on-front-end-design-basics/">
                            <img
                              width={100}
                              height={48}
                              src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3.jpg"
                              className="attachment-sixart-widget-blog-1 size-sixart-widget-blog-1 wp-post-image"
                              alt=""
                              decoding="async"
                              loading="lazy"
                              srcSet="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3.jpg 843w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3-600x286.jpg 600w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3-300x143.jpg 300w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3-768x366.jpg 768w"
                              sizes="(max-width: 100px) 100vw, 100px"
                            />{" "}
                          </a>
                        </div>
                        <div className="rc__post-content">
                          <div className="rc__meta">
                            <span>October 12, 2022</span>
                          </div>
                          <h3 className="rc__post-title">
                            <a href="https://data.themeim.com/wp/sixart/boost-your-skills-online-on-front-end-design-basics/">
                              Boost Your Skills Online, On
                            </a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="categories-2"
                  className="sidebar__widget mb-40 widget_categories"
                >
                  <h3 className="sidebar__widget-title">Categories</h3>
                  <ul style={{ padding: "0" }}>
                    <li className="cat-item cat-item-10">
                      <a href="https://data.themeim.com/wp/sixart/category/branding-design/">
                        BRANDING DESIGN{" "}
                        <span>
                          <i class="fal fa-angle-right"></i>
                        </span>
                      </a>
                    </li>
                    <li className="cat-item cat-item-11">
                      <a href="https://data.themeim.com/wp/sixart/category/photography/">
                        PHOTOGRAPHY{" "}
                        <span>
                          <i class="fal fa-angle-right"></i>
                        </span>
                      </a>
                    </li>
                    <li className="cat-item cat-item-12">
                      <a href="https://data.themeim.com/wp/sixart/category/statergy/">
                        STATERGY{" "}
                        <span>
                          <i class="fal fa-angle-right"></i>
                        </span>
                      </a>
                    </li>
                    <li className="cat-item cat-item-13">
                      <a href="https://data.themeim.com/wp/sixart/category/ui-ux-deisgn/">
                        UI/UX DEISGN{" "}
                        <span>
                          <i class="fal fa-angle-right"></i>
                        </span>
                      </a>
                    </li>
                    <li className="cat-item cat-item-9">
                      <a href="https://data.themeim.com/wp/sixart/category/web-design/">
                        WEB DESIGN{" "}
                        <span>
                          <i class="fal fa-angle-right"></i>
                        </span>
                      </a>
                    </li>
                    <li className="cat-item cat-item-14">
                      <a href="https://data.themeim.com/wp/sixart/category/web-development/">
                        WEB DEVELOPMENT{" "}
                        <span>
                          <i class="fal fa-angle-right"></i>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="sidebar__widget mb-40">
                  <h3 class="sidebar__widget-title">Tags</h3>
                  <div class="sidebar__widget-content">
                    <div class="tagcloud">
                      <a href="#">landing </a>
                      <a href="#">Charity</a>
                      <a href="#">apps</a>
                      <a href="#">Education </a>
                      <a href="#">data</a>
                      <a href="#">book</a>
                      <a href="#">Design</a>
                      <a href="#">website</a>
                      <a href="#">landing page</a>
                      <a href="#">data</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetail;
