import React, { useEffect } from "react";
import "./service.style.scss";
import { Link } from "react-router-dom";
import ContactUsForm from "../../components/contactUsForm/contactUsForm.component";

const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section
        className="breadcrumb__area breadcrumb-height include-bg p-relative"
        data-background="assets/img/breadcrumb/breadcurmb.jpg"
        style={{
          backgroundImage:
            'url("https://data.themeim.com/html/sixart/assets/img/breadcrumb/breadcurmb.jpg")',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h3
                  className="breadcrumb__title wow tpfadeUp"
                  data-wow-duration=".7s"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDuration: "0.7s",
                    animationDelay: "0.5s",
                    animationName: "tpfadeUp",
                  }}
                >
                  Service{" "}
                </h3>
                <div
                  className="breadcrumb__list wow tpfadeUp"
                  data-wow-duration=".9s"
                  style={{
                    visibility: "visible",
                    animationDuration: "0.9s",
                    animationName: "tpfadeUp",
                  }}
                >
                  <span>
                    <Link to="/">Home</Link>
                  </span>
                  <span className="dvdr">
                    <i className="fa fa-angle-right" />
                  </span>
                  <span>Our Service</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        data-elementor-type="wp-page"
        data-elementor-id={52}
        className="elementor elementor-52"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-d2acb87 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="d2acb87"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0c86f88"
              data-id="0c86f88"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-083b4ce elementor-widget elementor-widget-services"
                  data-id="083b4ce"
                  data-element_type="widget"
                  data-widget_type="services.default"
                >
                  <div className="elementor-widget-container">
                    <div className="tp-service-area pb-100">
                      <div className="container">
                        <div className="row">
                          <div
                            className="col-xl-4 col-lg-4 mb-30 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="0.3s"
                          >
                            <div className="service-item-three text-center">
                              <div className="service-item-three__img">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/11/sv-4.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="service-item-three__content">
                                <h4 className="tp-service-sm-title">
                                  <Link to="/service-detail/web-development">
                                    Web <br /> Development{" "}
                                  </Link>
                                </h4>
                                <p>
                                  Discover the cutting edge of innovative web
                                  creation with our complete portfolio of
                                  services designed to satisfy your online
                                  requirements.
                                </p>
                              </div>
                              <div className="service-item-three__button">
                                <Link to="/service-detail/web-development">
                                  <span>
                                    <svg
                                      width={34}
                                      height={16}
                                      viewBox="0 0 34 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                  READ MORE{" "}
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-xl-4 col-lg-4 mb-30 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="0.5s"
                          >
                            <div className="service-item-three text-center">
                              <div className="service-item-three__img">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/11/sv-5.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="service-item-three__content">
                                <h4 className="tp-service-sm-title">
                                  <Link to="/service-detail/mobile-development">
                                    {" "}
                                    Mobile <br /> Development{" "}
                                  </Link>
                                </h4>
                                <p>
                                  Discover the cutting edge of innovative web
                                  creation with our complete portfolio of
                                  services designed to satisfy your online
                                  requirements.
                                </p>
                              </div>
                              <div className="service-item-three__button">
                                <Link to="/service-detail/mobile-development">
                                  <span>
                                    <svg
                                      width={34}
                                      height={16}
                                      viewBox="0 0 34 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                  READ MORE{" "}
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-xl-4 col-lg-4 mb-30 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="0.6s"
                          >
                            <div className="service-item-three text-center">
                              <div className="service-item-three__img">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/11/sv-6.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="service-item-three__content">
                                <h4 className="tp-service-sm-title">
                                  <Link to="/service-detail/custom-solution">
                                    Web Custom <br /> Solution{" "}
                                  </Link>
                                </h4>
                                <p>
                                  Discover the cutting edge of innovative web
                                  creation with our complete portfolio of
                                  services designed to satisfy your online
                                  requirements.
                                </p>
                              </div>
                              <div className="service-item-three__button">
                                <Link to="/service-detail/custom-solution">
                                  <span>
                                    <svg
                                      width={34}
                                      height={16}
                                      viewBox="0 0 34 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                  READ MORE{" "}
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-xl-4 col-lg-4 mb-30 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="0.8s"
                          >
                            <div className="service-item-three text-center">
                              <div className="service-item-three__img">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/sv-1.png"
                                  alt=""
                                />
                              </div>
                              <div className="service-item-three__content">
                                <h4 className="tp-service-sm-title">
                                  <Link to="/service-detail/web-design">
                                    User Experience <br /> Design{" "}
                                  </Link>
                                </h4>
                                <p>
                                  Discover the cutting edge of innovative web
                                  creation with our complete portfolio of
                                  services designed to satisfy your online
                                  requirements.
                                </p>
                              </div>
                              <div className="service-item-three__button">
                                <Link to="/service-detail/web-design">
                                  <span>
                                    <svg
                                      width={34}
                                      height={16}
                                      viewBox="0 0 34 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                  READ MORE{" "}
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-xl-4 col-lg-4 mb-30 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="0.9s"
                          >
                            <div className="service-item-three text-center">
                              <div className="service-item-three__img">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/sv-2.png"
                                  alt=""
                                />
                              </div>
                              <div className="service-item-three__content">
                                <h4 className="tp-service-sm-title">
                                  <Link to="/service-detail/seo">SEO</Link>
                                </h4>
                                <p>
                                  Discover the cutting edge of innovative web
                                  creation with our complete portfolio of
                                  services designed to satisfy your online
                                  requirements.
                                </p>
                              </div>
                              <div className="service-item-three__button">
                                <Link to="/service-detail/seo">
                                  <span>
                                    <svg
                                      width={34}
                                      height={16}
                                      viewBox="0 0 34 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                  READ MORE{" "}
                                </Link>
                              </div>
                            </div>
                          </div>
                          {/* <div
                            className="col-xl-4 col-lg-4 mb-30 wow tpfadeUp"
                            data-wow-duration="0.7s"
                            data-wow-delay="0.9s"
                          >
                            <div className="service-item-three text-center">
                              <div className="service-item-three__img">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/sv-3.png"
                                  alt=""
                                />
                              </div>
                              <div className="service-item-three__content">
                                <h4 className="tp-service-sm-title">
                                  <a href="https://data.themeim.com/wp/sixart/service-details/">
                                    Ui Kit and 3d <br /> Desgin{" "}
                                  </a>
                                </h4>
                                <p>
                                  Discover the cutting edge of innovative web
                                  creation with our complete portfolio of
                                  services designed to satisfy your online
                                  requirements.
                                </p>
                              </div>
                              <div className="service-item-three__button">
                                <a
                                  href="https://data.themeim.com/wp/sixart/service-details/"
                                  target="_self"
                                  rel="nofollow"
                                >
                                  <span>
                                    <svg
                                      width={34}
                                      height={16}
                                      viewBox="0 0 34 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                  READ MORE{" "}
                                </a>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-8e866e7 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="8e866e7"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-dd1e6b6"
              data-id="dd1e6b6"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-a5b317e elementor-widget elementor-widget-about"
                  data-id="a5b317e"
                  data-element_type="widget"
                  data-widget_type="about.default"
                >
                  <div className="elementor-widget-container">
                    <div className="tp-about-area ab-area-sapce pt-120 pb-120">
                      <div className="container">
                        <div className="row align-items-center">
                          <div
                            className="col-xl-5 col-lg-5 order-2 order-lg-1 wow tpfadeLeft"
                            data-wow-duration=".7s"
                            data-wow-delay=".5s"
                          >
                            <div className="tp-ab-wrapper p-relative">
                              <div className="tp-ab-shape-one z-index-3">
                                <img
                                  decoding="async"
                                  src="https://data.themeim.com/wp/sixart/wp-content/themes/sixart/assets/img/about/about-shape-1.png"
                                  alt=""
                                />
                              </div>
                              <div className="tp-ab-shape-two z-index-3">
                                <img
                                  decoding="async"
                                  src="https://data.themeim.com/wp/sixart/wp-content/themes/sixart/assets/img/about/about-circle-shape.png"
                                  alt=""
                                />
                              </div>
                              <div className="tp-about-thumb">
                                <img
                                  decoding="async"
                                  src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/about-img.jpg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-xl-7 col-lg-7 order-1 order-lg-2 wow tpfadeRight"
                            data-wow-duration=".7s"
                            data-wow-delay=".7s"
                          >
                            <div className="tp-ab-section-title-box">
                              <h4 className="tp-section-subtitle tp-green-color">
                                Who we are?
                              </h4>
                              <h2 className="tp-section-title">
                                We are dynamic team of creative design and
                                development
                              </h2>
                              <p>
                                With our years of experience in offering
                                business solutions and consulting services, we
                                guarantee the best possible protection and
                                assistance.
                              </p>
                              <Link
                                to="/about-us"
                                target="_self"
                                rel="nofollow"
                                className="tp-btn"
                              >
                                ABOUT OUR AGENCY
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactUsForm />
      </div>
    </>
  );
};

export default Service;
