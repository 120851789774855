import React, { useEffect } from "react";
import "./contactUs.style.scss";
import { useStateValue } from "../../StateProvider";
import toast from "react-hot-toast";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const [, dispatch] = useStateValue();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const contactUs = async (data) => {
    dispatch({
      type: "SET_LOADING_STATUS",
      status: true,
    });
    try {
      const docRef = await addDoc(collection(db, "contact us"), {
        contactDetail: data,
      });
      toast.success("success");
      reset();
    } catch (e) {
      toast.error("somthing went wrong.");
    }
    dispatch({
      type: "SET_LOADING_STATUS",
      status: false,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section
        className="breadcrumb__area breadcrumb-height include-bg p-relative "
        style={{
          background:
            "url(https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/breadcurmb.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h3 className="breadcrumb__title">Contact</h3>
                <div className="breadcrumb__list p-0 mt-15">
                  {/* Breadcrumb NavXT 7.1.0 */}
                  <span property="itemListElement" typeof="ListItem">
                    <Link to="/" className="home">
                      <span property="name">Home </span>
                    </Link>
                    <meta property="position" content={1} />
                  </span>
                  <span className="dvdr">
                    <i className="fa fa-angle-right" />
                  </span>
                  <span property="itemListElement" typeof="ListItem">
                    <span
                      property="name"
                      className="post post-page current-item"
                    >
                      Contact
                    </span>
                    <meta
                      property="url"
                      content="https://data.themeim.com/wp/sixart/contact/"
                    />
                    <meta property="position" content={2} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        data-elementor-type="wp-page"
        data-elementor-id={62}
        className="elementor elementor-62"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-fecfab3 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="fecfab3"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6046018"
              data-id={6046018}
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-2bd8cc8 elementor-widget elementor-widget-contactform"
                  data-id="2bd8cc8"
                  data-element_type="widget"
                  data-widget_type="contactform.default"
                >
                  <div className="elementor-widget-container">
                    <div className="tp-contact-area pt-130 pb-130">
                      <div className="container">
                        <div className="row g-0 align-items-center justify-content-center">
                          <div className="col-xl-4 col-lg-4 col-md-5 col-12">
                            <div className="contact-box">
                              <div className="contact-box-circle">
                                <span>O</span>
                                <span>R</span>
                              </div>
                              <h2 className="contact-box__title">
                                Contact <br /> Directly
                              </h2>
                              <div className="contact-box__info-list">
                                <ul>
                                  <li>
                                    <a href="tel:(91) 9173848036">
                                      <i className="fal fa-phone-alt" />{" "}
                                      +91 9173848036
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.google.com/maps/place/Mighty+Scales+Infra+LLP+(Pantheon)/@23.2341825,69.6450674,17z/data=!3m1!4b1!4m6!3m5!1s0x39511f268e4db2df:0xf537219b3c07b715!8m2!3d23.2341776!4d69.6476477!16s%2Fg%2F11kb_8glmp?entry=ttu"
                                      target="_blank"
                                    >
                                      <i className="fal fa-map-marker-alt" />{" "}
                                      Office no 215, Pantheon, Mirjapar Road,
                                      Bhuj, kachchh, 370001.
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/cdn-cgi/l/email-protection#15787c767d707979703b677c6370677455706d74786579703b767a78">
                                      <i className="fal fa-globe" />
                                      <span
                                        className="__cf_email__"
                                        data-cfemail="5e363b322e1e3b263f332e323b703d3133"
                                      >
                                        info@snmsolution.in
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              {/* <div className="contact-box__social">
                            <ul>
                              <li>
                                <a href="#">
                                  <i className="fab fa-instagram" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fab fa-facebook-f" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fab fa-linkedin" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fab fa-twitter" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fab fa-youtube" />
                                </a>
                              </li>
                            </ul>
                          </div> */}
                            </div>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-7 col-12">
                            <div className="postbox__comment-form contact-wrapper">
                              <h3 className="postbox__comment-form-title">
                                Send us a Message
                              </h3>
                              <div
                                role="form"
                                className="wpcf7"
                                id="wpcf7-f1265-p62-o1"
                                lang="en-US"
                                dir="ltr"
                              >
                                <div className="screen-reader-response">
                                  <p
                                    role="status"
                                    aria-live="polite"
                                    aria-atomic="true"
                                  />
                                  <ul />
                                </div>
                                <form
                                  // action="/wp/sixart/contact/#wpcf7-f1265-p62-o1"
                                  // method="post"
                                  className="wpcf7-form init"
                                  // noValidate="novalidate"
                                  // data-status="init"
                                  onSubmit={handleSubmit((data) =>
                                    contactUs(data)
                                  )}
                                >
                                  <div style={{ display: "none" }}>
                                    <input
                                      type="hidden"
                                      name="_wpcf7"
                                      defaultValue={1265}
                                    />
                                    <input
                                      type="hidden"
                                      name="_wpcf7_version"
                                      defaultValue="5.6.3"
                                    />
                                    <input
                                      type="hidden"
                                      name="_wpcf7_locale"
                                      defaultValue="en_US"
                                    />
                                    <input
                                      type="hidden"
                                      name="_wpcf7_unit_tag"
                                      defaultValue="wpcf7-f1265-p62-o1"
                                    />
                                    <input
                                      type="hidden"
                                      name="_wpcf7_container_post"
                                      defaultValue={62}
                                    />
                                    <input
                                      type="hidden"
                                      name="_wpcf7_posted_data_hash"
                                      defaultValue=""
                                    />
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="postbox__comment-input">
                                        <span
                                          className="wpcf7-form-control-wrap"
                                          data-name="text-640"
                                        >
                                          <input
                                            type="text"
                                            name="text-640"
                                            defaultValue=""
                                            size={40}
                                            className="wpcf7-form-control wpcf7-text"
                                            aria-invalid="false"
                                            placeholder="Enter your name"
                                            {...register("name", {
                                              required: true,
                                            })}
                                            required
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="postbox__comment-input">
                                        <span
                                          className="wpcf7-form-control-wrap"
                                          data-name="email-665"
                                        >
                                          <input
                                            type="email"
                                            name="email-665"
                                            defaultValue=""
                                            size={40}
                                            className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email"
                                            aria-invalid="false"
                                            placeholder="Enter your mail"
                                            {...register("email", {
                                              required: true,
                                            })}
                                            required
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="postbox__comment-input">
                                        <span
                                          className="wpcf7-form-control-wrap"
                                          data-name="email-665"
                                        >
                                          <input
                                            type="number"
                                            name="email-665"
                                            defaultValue=""
                                            size={40}
                                            className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email"
                                            aria-invalid="false"
                                            placeholder="Enter your mobile number"
                                            {...register("phoneNumber", {
                                              required: true,
                                            })}
                                            required
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="postbox__comment-input">
                                        <span
                                          className="wpcf7-form-control-wrap"
                                          data-name="textarea-981"
                                        >
                                          <textarea
                                            name="textarea-981"
                                            cols={40}
                                            rows={10}
                                            className="wpcf7-form-control wpcf7-textarea"
                                            aria-invalid="false"
                                            placeholder="Enter your message"
                                            defaultValue={""}
                                            {...register("message", {
                                              required: true,
                                            })}
                                            required
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="postbox__comment-btn">
                                        <button
                                          type="submit"
                                          className="tp-btn"
                                        >
                                          Let's Talk
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="wpcf7-response-output"
                                    aria-hidden="true"
                                  />
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUs;
