import React from "react";
import Slider from "react-slick";
import "./blogs.style.scss";
import { Link } from "react-router-dom";

const Blogs = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <>
        <section
          className="breadcrumb__area breadcrumb-height include-bg p-relative "
          style={{
            background:
              "url(https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/breadcurmb.jpg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="breadcrumb__content">
                  <h3 className="breadcrumb__title">Recent Blog</h3>
                  <div
                    className="breadcrumb__list p-0 mt-15"
                    style={{ margin: "0", padding: "0px 10px !important" }}
                  >
                    {/* Breadcrumb NavXT 7.1.0 */}
                    <span property="itemListElement" typeof="ListItem">
                      <Link to="/" className="home">
                        <span property="name">HOME </span>
                      </Link>
                      <meta property="position" content={1} />
                    </span>
                    <span className="dvdr">
                      <i className="fa fa-angle-right" />
                    </span>
                    <span property="itemListElement" typeof="ListItem">
                      <span
                        property="name"
                        className="post-root post post-post current-item"
                      >
                        Blog
                      </span>
                      <meta
                        property="url"
                        content="https://data.themeim.com/wp/sixart/blog/"
                      />
                      <meta property="position" content={2} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tp-blog-area postbox__area pt-120 pb-120">
          <div className="container ">
            <div className="row">
              <div className="col-lg-8 blog-post-items blog-padding">
                <div className="postbox__wrapper pr-20">
                  <header>
                    {/* <h1 className="page-title screen-reader-text">Recent Blog</h1> */}
                  </header>
                  <article
                    id="post-181"
                    className="postbox__item format-image mb-50 transition-3 post-181 post type-post status-publish has-post-thumbnail hentry category-photography category-statergy category-ui-ux-deisgn category-web-development tag-apps tag-book tag-data post_format-post-format-image"
                  >
                    <div className="postbox__thumb w-img">
                      <a href="https://data.themeim.com/wp/sixart/building-a-retro-draggable-web-component-with-lit-ar-not-working/">
                        <img
                          width={843}
                          height={402}
                          src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details.jpg"
                          className="img-responsive wp-post-image"
                          alt=""
                          decoding="async"
                          fetchpriority="high"
                          srcSet="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details.jpg 843w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-600x286.jpg 600w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-300x143.jpg 300w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-768x366.jpg 768w"
                          sizes="(max-width: 843px) 100vw, 843px"
                          style={{ height: "auto" }}
                        />{" "}
                      </a>
                    </div>
                    <div className="postbox__content">
                      {/* blog meta */}
                      <div className="postbox__meta">
                        <span>
                          <a href="https://data.themeim.com/wp/sixart/author/sixart/">
                            <i className="fal fa-user-circle" /> sixart{" "}
                          </a>
                        </span>
                        <span>
                          <a href="https://data.themeim.com/wp/sixart/2022/10/12/">
                            <i className="fal fa-clock" /> October 12, 2022
                          </a>
                        </span>
                        <span>
                          <a href="https://data.themeim.com/wp/sixart/building-a-retro-draggable-web-component-with-lit-ar-not-working/#respond">
                            <i className="fal fa-comment-alt-lines" /> No
                            Comments
                          </a>
                        </span>
                      </div>
                      <h3 className="postbox__title">
                        <a href="https://data.themeim.com/wp/sixart/building-a-retro-draggable-web-component-with-lit-ar-not-working/">
                          Building A Retro Draggable Web Component With Lit ar
                          not working
                        </a>
                      </h3>
                      <div className="postbox__text">
                        <p>
                          Nancy boy Charles down the pub get stuffed mate easy
                          peasy brown bread car boot squiffy loo, blimey arse
                          over tit it’s your round cup of char horse play
                          chimney pot old. Chip shop bonnet barney owt to do
                          with me what a plonker hotpot loo that gormless off
                          his nut a blinding shot Harry […]
                        </p>
                      </div>
                      {/* blog btn */}
                      <div className="post__button">
                        <a
                          className="tp-btn-sm"
                          href="https://data.themeim.com/wp/sixart/building-a-retro-draggable-web-component-with-lit-ar-not-working/"
                        >
                          {" "}
                          Read More
                        </a>
                      </div>
                    </div>
                  </article>
                  <article
                    id="post-179"
                    className="postbox__item format-gallery mb-50 transition-3 post-179 post type-post status-publish has-post-thumbnail hentry category-photography category-statergy category-web-design category-web-development tag-apps tag-book tag-data tag-landing post_format-post-format-gallery"
                  >
                    <div className="blog-wrapper">
                      <div className="blog-post-slider-active">
                        <Slider {...settings}>
                          <div className="blog-slider-item">
                            <img
                              src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-2.jpg"
                              alt=""
                            />
                          </div>
                          <div className="blog-slider-item">
                            <img
                              src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3.jpg"
                              alt=""
                            />
                          </div>
                          <div className="blog-slider-item">
                            <img
                              src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details.jpg"
                              alt=""
                            />
                          </div>
                        </Slider>
                      </div>
                    </div>
                    <div className="postbox__content">
                      {/* blog meta */}
                      <div className="postbox__meta">
                        <span>
                          <a href="https://data.themeim.com/wp/sixart/author/sixart/">
                            <i className="fal fa-user-circle" /> sixart{" "}
                          </a>
                        </span>
                        <span>
                          <a href="https://data.themeim.com/wp/sixart/2022/10/12/">
                            <i className="fal fa-clock" /> October 12, 2022
                          </a>
                        </span>
                        <span>
                          <a href="https://data.themeim.com/wp/sixart/meet-smart-interface-design-patterns-checklists-with-166-cards/#respond">
                            <i className="fal fa-comment-alt-lines" /> No
                            Comments
                          </a>
                        </span>
                      </div>
                      <h3 className="postbox__title">
                        <a href="https://data.themeim.com/wp/sixart/meet-smart-interface-design-patterns-checklists-with-166-cards/">
                          Meet Smart Interface Design Patterns Checklists (With
                          166 Cards)
                        </a>
                      </h3>
                      <div className="postbox__text">
                        <p>
                          Nancy boy Charles down the pub get stuffed mate easy
                          peasy brown bread car boot squiffy loo, blimey arse
                          over tit it’s your round cup of char horse play
                          chimney pot old. Chip shop bonnet barney owt to do
                          with me what a plonker hotpot loo that gormless off
                          his nut a blinding shot Harry […]
                        </p>
                      </div>
                      {/* blog btn */}
                      <div className="post__button">
                        <a
                          className="tp-btn-sm"
                          href="https://data.themeim.com/wp/sixart/meet-smart-interface-design-patterns-checklists-with-166-cards/"
                        >
                          {" "}
                          Read More
                        </a>
                      </div>
                    </div>
                  </article>
                  <article
                    id="post-176"
                    className="postbox__item format-video mb-50 transition-3 post-176 post type-post status-publish has-post-thumbnail hentry category-branding-design category-ui-ux-deisgn category-web-design category-web-development tag-apps tag-book tag-data tag-landing post_format-post-format-video"
                  >
                    <div className="postbox__thumb postbox__video w-img p-relative">
                      <a href="https://data.themeim.com/wp/sixart/boost-your-skills-online-on-front-end-design-basics/">
                        <img
                          width={843}
                          height={402}
                          src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3.jpg"
                          className="img-responsive wp-post-image"
                          alt=""
                          decoding="async"
                          srcSet="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3.jpg 843w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3-600x286.jpg 600w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3-300x143.jpg 300w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3-768x366.jpg 768w"
                          sizes="(max-width: 843px) 100vw, 843px"
                        />{" "}
                      </a>
                      <a
                        href="https://youtube.com/watch?v=OidALOr3yWA"
                        className="play-btn pulse-btn popup-video"
                      >
                        <i className="fas fa-play" />
                      </a>
                    </div>
                    <div className="postbox__content">
                      {/* blog meta */}
                      <div className="postbox__meta">
                        <span>
                          <a href="https://data.themeim.com/wp/sixart/author/sixart/">
                            <i className="fal fa-user-circle" /> sixart{" "}
                          </a>
                        </span>
                        <span>
                          <a href="https://data.themeim.com/wp/sixart/2022/10/12/">
                            <i className="fal fa-clock" /> October 12, 2022
                          </a>
                        </span>
                        <span>
                          <a href="https://data.themeim.com/wp/sixart/boost-your-skills-online-on-front-end-design-basics/#respond">
                            <i className="fal fa-comment-alt-lines" /> No
                            Comments
                          </a>
                        </span>
                      </div>
                      <h3 className="postbox__title">
                        <a href="https://data.themeim.com/wp/sixart/boost-your-skills-online-on-front-end-design-basics/">
                          Boost Your Skills Online, On Front-End &amp; Design
                          Basics
                        </a>
                      </h3>
                      <div className="postbox__text">
                        <p>
                          Nancy boy Charles down the pub get stuffed mate easy
                          peasy brown bread car boot squiffy loo, blimey arse
                          over tit it’s your round cup of char horse play
                          chimney pot old. Chip shop bonnet barney owt to do
                          with me what a plonker hotpot loo that gormless off
                          his nut a blinding shot Harry […]
                        </p>
                      </div>
                      {/* blog btn */}
                      <div className="post__button">
                        <a
                          className="tp-btn-sm"
                          href="https://data.themeim.com/wp/sixart/boost-your-skills-online-on-front-end-design-basics/"
                        >
                          {" "}
                          Read More
                        </a>
                      </div>
                    </div>
                  </article>
                  <div class="basic-pagination">
                    <nav>
                      <ul>
                        <li>
                          <a href="blog.html">
                            <i class="fal fa-arrow-left"></i>
                          </a>
                        </li>
                        <li>
                          <a href="blog.html">1</a>
                        </li>
                        <li>
                          <span class="current">2</span>
                        </li>
                        <li>
                          <a href="blog.html">3</a>
                        </li>
                        <li>
                          <a href="blog.html">
                            <i class="fal fa-arrow-right"></i>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4">
                <div className="blog__sidebar sidebar__wrapper">
                  <div
                    id="search-2"
                    className="sidebar__widget mb-40 widget_search"
                  >
                    <h3 className="sidebar__widget-title">Search Here</h3>
                    <div className="sidebar__widget-content">
                      <div className="sidebar__search">
                        <form
                          action="https://data.themeim.com/wp/sixart/"
                          method="get"
                        >
                          <div className="sidebar__search-input-2">
                            <input
                              type="text"
                              defaultValue=""
                              required=""
                              name="s"
                              placeholder="Search your keyword..."
                            />
                            <button type="submit">
                              <i className="far fa-search" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    id="tp-latest-posts-2"
                    className="sidebar__widget mb-40 widget_tp-latest-posts"
                  >
                    <h3 className="sidebar__widget-title"> Recent Post </h3>
                    <div className="sidebar__widget-content">
                      <div className="sidebar__post rc__post">
                        <div className="rc__post mb-20 d-flex">
                          <div className="rc__post-thumb mr-20">
                            <a href="https://data.themeim.com/wp/sixart/building-a-retro-draggable-web-component-with-lit-ar-not-working/">
                              <img
                                width={100}
                                height={48}
                                src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details.jpg"
                                className="attachment-sixart-widget-blog-1 size-sixart-widget-blog-1 wp-post-image"
                                alt=""
                                decoding="async"
                                loading="lazy"
                                srcSet="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details.jpg 843w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-600x286.jpg 600w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-300x143.jpg 300w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-768x366.jpg 768w"
                                sizes="(max-width: 100px) 100vw, 100px"
                              />{" "}
                            </a>
                          </div>
                          <div className="rc__post-content">
                            <div className="rc__meta">
                              <span>October 12, 2022</span>
                            </div>
                            <h3 className="rc__post-title">
                              <a href="https://data.themeim.com/wp/sixart/building-a-retro-draggable-web-component-with-lit-ar-not-working/">
                                Building A Retro Draggable Web
                              </a>
                            </h3>
                          </div>
                        </div>
                        <div className="rc__post mb-20 d-flex">
                          <div className="rc__post-thumb mr-20">
                            <a href="https://data.themeim.com/wp/sixart/meet-smart-interface-design-patterns-checklists-with-166-cards/">
                              <img
                                width={100}
                                height={48}
                                src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-2.jpg"
                                className="attachment-sixart-widget-blog-1 size-sixart-widget-blog-1 wp-post-image"
                                alt=""
                                decoding="async"
                                loading="lazy"
                                srcSet="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-2.jpg 843w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-2-600x286.jpg 600w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-2-300x143.jpg 300w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-2-768x366.jpg 768w"
                                sizes="(max-width: 100px) 100vw, 100px"
                              />{" "}
                            </a>
                          </div>
                          <div className="rc__post-content">
                            <div className="rc__meta">
                              <span>October 12, 2022</span>
                            </div>
                            <h3 className="rc__post-title">
                              <a href="https://data.themeim.com/wp/sixart/meet-smart-interface-design-patterns-checklists-with-166-cards/">
                                Meet Smart Interface Design Patterns
                              </a>
                            </h3>
                          </div>
                        </div>
                        <div className="rc__post mb-20 d-flex">
                          <div className="rc__post-thumb mr-20">
                            <a href="https://data.themeim.com/wp/sixart/boost-your-skills-online-on-front-end-design-basics/">
                              <img
                                width={100}
                                height={48}
                                src="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3.jpg"
                                className="attachment-sixart-widget-blog-1 size-sixart-widget-blog-1 wp-post-image"
                                alt=""
                                decoding="async"
                                loading="lazy"
                                srcSet="https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3.jpg 843w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3-600x286.jpg 600w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3-300x143.jpg 300w, https://data.themeim.com/wp/sixart/wp-content/uploads/2022/10/blog-details-3-768x366.jpg 768w"
                                sizes="(max-width: 100px) 100vw, 100px"
                              />{" "}
                            </a>
                          </div>
                          <div className="rc__post-content">
                            <div className="rc__meta">
                              <span>October 12, 2022</span>
                            </div>
                            <h3 className="rc__post-title">
                              <a href="https://data.themeim.com/wp/sixart/boost-your-skills-online-on-front-end-design-basics/">
                                Boost Your Skills Online, On
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="categories-2"
                    className="sidebar__widget mb-40 widget_categories"
                  >
                    <h3 className="sidebar__widget-title">Categories</h3>
                    <ul style={{ padding: "0" }}>
                      <li className="cat-item cat-item-10">
                        <a href="https://data.themeim.com/wp/sixart/category/branding-design/">
                          BRANDING DESIGN{" "}
                          <span>
                            <i class="fal fa-angle-right"></i>
                          </span>
                        </a>
                      </li>
                      <li className="cat-item cat-item-11">
                        <a href="https://data.themeim.com/wp/sixart/category/photography/">
                          PHOTOGRAPHY{" "}
                          <span>
                            <i class="fal fa-angle-right"></i>
                          </span>
                        </a>
                      </li>
                      <li className="cat-item cat-item-12">
                        <a href="https://data.themeim.com/wp/sixart/category/statergy/">
                          STATERGY{" "}
                          <span>
                            <i class="fal fa-angle-right"></i>
                          </span>
                        </a>
                      </li>
                      <li className="cat-item cat-item-13">
                        <a href="https://data.themeim.com/wp/sixart/category/ui-ux-deisgn/">
                          UI/UX DEISGN{" "}
                          <span>
                            <i class="fal fa-angle-right"></i>
                          </span>
                        </a>
                      </li>
                      <li className="cat-item cat-item-9">
                        <a href="https://data.themeim.com/wp/sixart/category/web-design/">
                          WEB DESIGN{" "}
                          <span>
                            <i class="fal fa-angle-right"></i>
                          </span>
                        </a>
                      </li>
                      <li className="cat-item cat-item-14">
                        <a href="https://data.themeim.com/wp/sixart/category/web-development/">
                          WEB DEVELOPMENT{" "}
                          <span>
                            <i class="fal fa-angle-right"></i>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="sidebar__widget mb-40">
                    <h3 class="sidebar__widget-title">Tags</h3>
                    <div class="sidebar__widget-content">
                      <div class="tagcloud">
                        <a href="#">landing </a>
                        <a href="#">Charity</a>
                        <a href="#">apps</a>
                        <a href="#">Education </a>
                        <a href="#">data</a>
                        <a href="#">book</a>
                        <a href="#">Design</a>
                        <a href="#">website</a>
                        <a href="#">landing page</a>
                        <a href="#">data</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </div>
  );
};

export default Blogs;
